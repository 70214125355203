import React, { useState, useEffect, useCallback } from 'react';
import UserCodeForm from '../../components/authorization/UserCodeForm';
import { withRouter } from 'react-router-dom';
import { withTranslate } from '../utils/HigherOrderComponents';
import { submitAuthorizationCode } from '../../redux/thunks/configurationsThunks';
import { connect } from 'react-redux';
import Page from '../../components/layout/Page';
import ContainerHeader from '../../components/layout/ContainerHeader';
import LanguageSwitch from '../../components/utils/LanguageSwitch';
import { formValueSelector } from 'redux-form';
import { HeaderLogo } from '../../components/layout/Logo';
import { Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { Cancel } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

const UserCodeContainer = ({ history, location, translate, submitCode, userCode }) => {
    const [authorizationData, setAuthorizationData] = useState({ isCompleted: false, inProgress: false, isSuccesful: false });
    const [queryCode, setQueryCode] = useState();
    const search = location.search;
    const codeFromQuery = new URLSearchParams(search).get('code');

    useEffect(() => {
        setQueryCode(codeFromQuery);
    }, [codeFromQuery]);

    const onSubmit = useCallback(
        async formData => {
            setAuthorizationData({ ...authorizationData, inProgress: true });
            const result = await submitCode(formData.code);
            if (result) {
                setAuthorizationData({ isSuccesful: true, inProgress: false, isCompleted: true });
            } else {
                setAuthorizationData({ isSuccesful: false, inProgress: false, isCompleted: true });
                history.replace(location.pathName);
            }
        },
        [authorizationData, submitCode, history, location.pathName]
    );

    useEffect(() => {
        if (userCode.length >= 5) {
            onSubmit({ code: userCode });
        }
    }, [userCode, onSubmit]);

    return (
        <Page maxWidth>
            <div className="login-page layout-content">
                <ContainerHeader iconComponent={Lock} title={translate('Authorization')} />
                <Box m={2} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <div className="subtitle line line1">{translate('WelcomeTo')}</div>
                    <div className="line line2">
                        <HeaderLogo />
                    </div>
                    {authorizationData.isCompleted && authorizationData.isSuccesful ? (
                        <Box mb="40px">
                            <Typography color="primary">
                                <Box display="flex" justifyContent="center" alignItems="center" fontSize="24px">
                                    <CheckCircle />
                                    <Box ml={1}>{translate('AuthorizationComplete')} </Box>
                                </Box>
                            </Typography>
                            <Box my={1}>
                                <div className="line line3 subtitle-small">
                                    {translate('YouCanCloseTheBrowserAndSwitchBackToApplication')}
                                </div>
                            </Box>
                        </Box>
                    ) : (
                        <React.Fragment>
                            <Box mb={1}>
                                <div className="line line3 subtitle-small">{translate('ProvideVerificationCodeToComplete')}</div>
                            </Box>

                            {authorizationData.inProgress ? (
                                <CircularProgress />
                            ) : (
                                <Box mb="40px">
                                    {authorizationData.isCompleted && !authorizationData.isSuccesful && (
                                        <Typography color="error">
                                            <Box display="flex" justifyContent="center" alignItems="center" fontSize="24px" mb={1}>
                                                <Cancel />
                                                <Box ml={1}>{translate('AuthorizationRejected')} </Box>
                                            </Box>
                                        </Typography>
                                    )}
                                    <UserCodeForm initialValues={{ code: queryCode }} translate={translate} onSubmit={onSubmit} />
                                </Box>
                            )}
                        </React.Fragment>
                    )}
                </Box>
                <div className="content">
                    <div className="footer">
                        <LanguageSwitch />
                    </div>
                </div>
            </div>
        </Page>
    );
};
const selector = formValueSelector('userCode');

function mapStateToProps(state, ownProps) {
    return {
        userCode: selector(state, 'code') || '',
    };
}
function mapDispatchToProps(dispatch) {
    return {
        submitCode: code => dispatch(submitAuthorizationCode(code)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(UserCodeContainer)));
