import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authorizationThunks from '../../redux/thunks/authorizationThunks';
import LoginContainer from './LoginContainer';
import RegisterContainer from './RegisterContainer';
import MainLoadingIndicator from '../../containers/utils/MainLoadingIndicator';
import LogoutPage from '../../components/authorization/LogoutPage';
import { Route, Switch } from 'react-router-dom';
import Page from '../../components/layout/Page';

class NonAuthorizedLayout extends Component {
    redirectToPath = pathname => {
        this.props.history.location.pathname !== pathname && this.props.history.push(pathname);
    };

    render() {
        return (
            <React.Fragment>
                <MainLoadingIndicator />
                <Switch>
                    <Route path="/logout" exact render={() => <LogoutPage translate={this.props.translate} />} />
                    <Route
                        path="/"
                        render={() => (
                            <Page>
                                <LoginContainer
                                    microsoftApplicationId={this.props.microsoftApplicationId}
                                    googleApplicationId={this.props.googleApplicationId}
                                    localLoginEnabled={this.props.localLoginEnabled}
                                />
                                <RegisterContainer
                                    microsoftApplicationId={this.props.microsoftApplicationId}
                                    googleApplicationId={this.props.googleApplicationId}
                                    localLoginEnabled={this.props.localLoginEnabled}
                                />
                            </Page>
                        )}
                    />
                </Switch>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        microsoftApplicationId: state.configurationsData.microsoftApplicationId,
        googleApplicationId: state.configurationsData.googleApplicationId,
        localLoginEnabled: state.configurationsData.localLoginEnabled,
        userCompanies: state.user.companies,
        translate: ownProps.translate,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logIn: data => dispatch(authorizationThunks.logIn(data)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NonAuthorizedLayout));
