import * as actionTypes from '../actions/types/authorizationActionTypes';
import { CONFIRM_INVITED_USER_SUCCEED } from '../actions/types/userActionTypes';
import { TOKEN, REFRESH_TOKEN, RETURN_URL } from '../../utils/constants';

let defaultState = {
    isLoggedIn: false,
    loginChecked: false,
    email: '',
    isExternalFormSubmitting: false,
    isActivateBySolteqSucceed: false,
    companies: [],
    loginData: null,
    credentialsValid: false,
    registrationType: '',
    loginPageMessage: null,
    inactiveCompany: false,
    loginFormMessage: null,
    returnUrl: null,
    roles: [],
};

export default function authorizationReducer(state = defaultState, action) {
    switch (action.type) {
        case actionTypes.LOG_IN_STARTED: {
            return Object.assign({}, state);
        }
        case actionTypes.SET_EXTERNAL_FORM_SUBMITING_FLAG: {
            state.isExternalFormSubmitting = action.isExternalFormSubmitting;

            return Object.assign({}, state);
        }
        case actionTypes.LOG_IN_SUCCEED: {
            state.isLoggedIn = true;
            state.loginChecked = true;
            state.isExternalFormSubmitting = false;
            state.returnUrl = action.data.returnUrl;
            state.companies = [];
            return Object.assign({}, state);
        }
        case actionTypes.REGISTER_REQUEST: {
            state.email = action.email;
            state.externalToken = action.externalToken;
            state.grantType = action.grantType;
            state.registerFormMode = null;
            state.isExternalFormSubmitting = false;

            return Object.assign({}, state);
        }
        case actionTypes.LOG_OUT: {
            state.isLoggedIn = false;
            state.loginChecked = true;
            state.email = '';
            state.loginFormMessage = null;
            delete state.externalToken;
            localStorage.removeItem(TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            return Object.assign({}, state);
        }
        case actionTypes.REGISTER_STARTED: {
            state.registrationType = action.registrationType;
            return Object.assign({}, state);
        }
        case actionTypes.CHECK_IF_AUTHORIZED_SUCCEED: {
            state.returnUrl = action.data.returnUrl;
            state.isLoggedIn = action.data.isLoggedIn;
            state.roles = action.data.roles;
            state.loginChecked = true;
            return Object.assign({}, state);
        }
        case actionTypes.RESET_PASSWORD_SUCCEED: {
            state.loginFormMessage = action.message;
            return Object.assign({}, state);
        }
        case CONFIRM_INVITED_USER_SUCCEED: {
            return {
                ...state,
                isConfirmInvitedUserSucceed: true,
                loginPageMessage: action.message,
                inactiveCompany: action.inactiveCompany,
            };
        }
        case actionTypes.ACTIVATE_BY_SOLTEQ_SUCCEED: {
            state.isActivateBySolteqSucceed = true;
            return Object.assign({}, state);
        }
        case actionTypes.CHECK_USER_CREDENTIALS_STARTED: {
            return {
                ...state,
                loginData: action.loginData,
            };
        }
        case actionTypes.CHECK_USER_CREDENTIALS_SUCCEED: {
            return {
                ...state,
                companies: action.data.companies,
                returnUrl: action.data.returnUrl,
                credentialsValid: true,
            };
        }
        case actionTypes.RESET_CREDENTIALS: {
            return {
                ...defaultState,
                loginPageMessage: state.loginPageMessage,
            };
        }
        case actionTypes.ACTIVATE_BY_SOLTEQ_FAILED: {
            state.isActivateBySolteqSucceed = false;
            state.activateBySolteqFailedMessage = action.message;
            return Object.assign({}, state);
        }
        case actionTypes.CONFIRM_EMAIL_SUCCEED: {
            state.isConfirmEmailSucceed = true;
            return Object.assign({}, state);
        }
        case actionTypes.RESET_MESSAGES: {
            return {
                ...state,
                loginPageMessage: null,
                loginFormMessage: null,
            };
        }
        case actionTypes.SET_RETURN_URL: {
            localStorage.setItem(RETURN_URL, action.returnUrl);
            return {
                ...state,
                returnUrl: action.returnUrl,
            };
        }
        case actionTypes.RESET_RETURN_URL: {
            localStorage.removeItem(RETURN_URL);
            return {
                ...state,
                returnUrl: undefined,
            };
        }
        default: {
            return Object.assign({}, state);
        }
    }
}
