/*eslint no-useless-escape: "off"*/
import uk from './flags/uk.svg';
import fi from './flags/fi.svg';
import sv from './flags/sv.svg';
import pl from './flags/pl.svg';
import da from './flags/da.svg';

export const LANGUAGES = [
    { name: 'English', code: 'EN', flag: uk },
    { name: 'Finnish', code: 'FI', flag: fi },
    { name: 'Swedish', code: 'SV', flag: sv },
    { name: 'Polish', code: 'PL', flag: pl },
    { name: 'Danish', code: 'DA', flag: da },
];

export const LANGUAGES_CODE = {
    EN: 'EN',
    FI: 'FI',
    SV: 'SV',
    PL: 'PL',
    DA: 'DA',
};

export const STORE_LANGUAGE_CODES = [
    { name: 'English', code: 'en-GB' },
    { name: 'Finnish', code: 'fi-FI' },
    { name: 'Swedish', code: 'sv-SV' },
    { name: 'Polish', code: 'pl-PL' },
    { name: 'Danish', code: 'da-DK' },
];

export const LOCAL_REGISTATION_TYPES = {
    EXISTING_ACCOUNT: 'RequireExisting',
    NEW_ACCOUNT: 'RequireNew',
};

export const REGISTRATION_TYPES = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

export const GRANT_TYPES = {
    LOCAL: 'localAuth',
    GOOGLE: 'googleAuth',
    OFFICE: 'officeAuth',
    REFRESH_TOKEN: 'refresh_token',
    EXCHANGE: 'exchange',
};

export const ROLES = {
    ADMIN: 'Admin',
};

export const NOTIFICATION_MESSAGE_TYPES = {
    FAIL: 'Fail',
    INFO: 'Info',
    SUCCESS: 'Success',
};

export const ACCOUNT_TYPES = [
    { label: 'Internal', value: REGISTRATION_TYPES.INTERNAL },
    { label: 'External', value: REGISTRATION_TYPES.EXTERNAL },
];

export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const TRANSLATIONS = 'TRANSLATIONS';
export const RETURN_URL = 'RETURN_URL';

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
