import { fetchWrapper } from '../../utils/fetchWrapper';
import { defaultHeaders } from './defaults';
import * as routes from '../../api/routes';
import * as errorDescriptionActions from '../actions/errorDescriptionActions';

export function fetchErrorDescription(errorId) {
    return dispatch => {
        return fetchWrapper(`${routes.ERRORS_URL}?errorId=${errorId}`, dispatch, {
            method: 'GET',
            headers: defaultHeaders,
        })
            .withSuccessHandler(errorDescription => dispatch(errorDescriptionActions.errorDescriptionRetrieved(errorDescription)))
            .start();
    };
}
