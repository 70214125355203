import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authorizationReducer from './authorizationReducer';
import { localeReducer } from 'react-localize-redux';
import { reducer as formReducer } from 'redux-form';
import notificationReducer from './notificationReducer';
import configurationsReducer from './configurationsReducer';
import userInvitationReducer from './userInvitationReducer';
import applicationReducer from './subscriptionReducer';
import errorDescriptionReducer from './errorDescriptionReducer';

const rootReducer = combineReducers({
    toastr: toastrReducer,
    locale: localeReducer,
    form: formReducer,
    user: authorizationReducer,
    notificationData: notificationReducer,
    configurationsData: configurationsReducer,
    invitation: userInvitationReducer,
    subscription: applicationReducer,
    errorDescription: errorDescriptionReducer,
});

export default rootReducer;
