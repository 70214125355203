import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { SelectFieldRenderer } from '../../utils/ReduxFormMaterialUiRenderers';
import { withTranslate } from '../../../containers/utils/HigherOrderComponents';
import { required } from '../../../utils/validators';
import * as constants from '../../../utils/constants';
import LanguageSwitch from '../../utils/LanguageSwitch';
import TextFieldRenderer from '../../renderers/TextFieldRenderer';
import { LinearProgress, Button } from '@material-ui/core';

class InviteUserContainer extends Component {
    emailValidation = value => {
        const regexp = constants.EMAIL_REGEX;
        return regexp.test(String(value).toLowerCase()) ? undefined : this.props.translate('Form_InvalidEmailFormat');
    };

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                {this.props.submitting ? <LinearProgress /> : ''}
                <div className="content">
                    <div className="subtitle line line1">{this.props.translate('InviteUserToCompany')}</div>
                    <div className="local-login-container responsive-form-container">
                        <div className="form-row">
                            <Field
                                component={TextFieldRenderer}
                                label={this.props.translate('Email')}
                                id="email"
                                name="email"
                                type="text"
                                validate={[required, this.emailValidation]}
                                disabled={this.props.submitting}
                            />
                        </div>
                        <div className="form-row">
                            <Field
                                component={SelectFieldRenderer}
                                label={this.props.translate('Role')}
                                id="role"
                                name="role"
                                options={this.props.availableRoles.map(r => ({
                                    value: r.name,
                                    label: r.name,
                                }))}
                                validate={[required]}
                                disabled={this.props.submitting}
                            />
                        </div>
                        <div className="form-row">
                            <Field
                                component={SelectFieldRenderer}
                                label={this.props.translate('AccountType')}
                                id="accountType"
                                name="accountType"
                                options={constants.ACCOUNT_TYPES.map(at => ({
                                    value: at.value,
                                    label: at.label,
                                }))}
                                validate={[required]}
                                disabled={this.props.submitting}
                            />
                        </div>
                        <div className="form-row">
                            <Button variant="contained" color="primary" fullWidth type="submit" disabled={this.props.submitting}>
                                {this.props.translate('Submit')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <LanguageSwitch />
                </div>
            </form>
        );
    }
}
const inviteUserContainer = withTranslate(InviteUserContainer);

export default reduxForm({ form: 'inviteUser' })(inviteUserContainer);
