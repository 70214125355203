import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LanguageSwitch from '../../utils/LanguageSwitch';
import { withTranslate } from '../../../containers/utils/HigherOrderComponents';
import { Button } from '@material-ui/core';
import AutoComplete from '../../solteqComponents/AutoComplete';
import ContainerHeader from '../../layout/ContainerHeader';
import BusinessIcon from '@material-ui/icons/Business';
import { Link } from '@material-ui/core';

class ChooseCompany extends Component {
    componentWillMount() {
        this.hasOneCompany() && this.props.logIn(this.props.companies[0].companyTaxCode);
    }

    hasOneCompany = () => this.props.companies && this.props.companies.length === 1;

    hasMoreThanOneCompany = () => this.props.companies && this.props.companies.length > 1;

    login(companyTaxCode) {
        this.props.logIn(companyTaxCode);
    }

    render() {
        return this.hasMoreThanOneCompany() ? (
            <div className="choose-company-page">
                <ContainerHeader
                    iconComponent={BusinessIcon}
                    title={this.props.translate('Choose')}
                    subtitle={this.props.translate('Company')}
                />
                <div className="content">
                    <div className="line line2 subtitle-small">{this.props.translate('PleaseSelectCompany')}</div>
                    <div className="responsive-form-container">
                        {this.props.companies.length <= 6 &&
                            this.props.companies.map(p => (
                                <div key={p.companyTaxCode} className="form-row">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        key={p.companyTaxCode}
                                        onClick={() => this.props.logIn(p.companyTaxCode)}
                                    >
                                        {p.companyName}
                                    </Button>
                                </div>
                            ))}
                        {this.props.companies.length > 6 && (
                            <div className="autocomplete-wrapper">
                                <AutoComplete
                                    id="choose-company"
                                    label={this.props.translate('PleaseSelectCompany')}
                                    onChange={p => this.login(p.value)}
                                    options={this.props.companies.map(p => ({
                                        label: p.companyName,
                                        value: p.companyTaxCode,
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                    <div className="line line4 subtitle-small">
                        <Link underline="none" href="#/login">
                            {this.props.translate('GoBack')}
                        </Link>
                    </div>
                </div>
                <div className="footer">
                    <LanguageSwitch />
                </div>
            </div>
        ) : (
            <span />
        );
    }
}

export default withTranslate(withRouter(ChooseCompany));
