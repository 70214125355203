import React from 'react';
import logo from '../../images/cloud-commerce-logo.svg';
import { makeStyles, useTheme } from '@material-ui/core';

export const HeaderLogo = () => {
    const classes = useStyles();
    return <Logo className={classes.logo} />;
};

const useStyles = makeStyles({
    logo: {
        width: '66%',
    },
});

export const Logo = ({ className = undefined }) => {
    const theme = useTheme();
    const { header } = theme;
    return <img src={header.logo ? header.logo : logo} className={className} alt="Solteq Identity Server" />;
};
