import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
    return {
        root: ({ multiline }) => ({
            height: multiline ? 'auto' : theme.input.height,
        }),
    };
});

const TextFieldRenderer = ({ type, input: { value, onChange, onBlur }, meta: { touched, error, warning, invalid }, ...restProps }) => {
    const classes = useStyles({ multiline: restProps.multiline });
    let inputProps = {
        type,
        value,
        label: restProps.label,
        name: restProps.name,
        color: 'primary',
        variant: 'outlined',
        fullWidth: restProps.fullWidth,
        helperText: touched ? error || warning : '',
        onBlur,
        onChange: e => onChange(e.target.value),
        error: touched && invalid,
        multiline: restProps.multiline,
        rows: restProps.rows,
        disabled: restProps.disabled,
        InputProps: {
            className: classes.root,
        },
    };
    return <TextField {...inputProps} />;
};

export default TextFieldRenderer;
