import { notificationMessage } from '../actions/notificationActions';
import { NOTIFICATION_MESSAGE_TYPES } from '../../utils/constants';

const successActionPrefixes = ['SAVE', 'UPDATE', 'REMOVE', 'INVITE', 'RESEND_INVITATION'];
const failedActionPrefixes = ['GET', ...successActionPrefixes];

const isUnsafeAction = (actionType, allowedActions) => allowedActions.some(i => actionType.startsWith(i));

export const notificationMiddleWare = store => next => action => {
    next(action);
    if (isUnsafeAction(action.type, successActionPrefixes) && action.type.endsWith('SUCCEED')) {
        store.dispatch(notificationMessage(NOTIFICATION_MESSAGE_TYPES.SUCCESS, action.message, action.messageKey));
    } else if (isUnsafeAction(action.type, failedActionPrefixes) && action.type.endsWith('FAILED')) {
        store.dispatch(notificationMessage(NOTIFICATION_MESSAGE_TYPES.FAIL, action.message, action.messageKey));
    }
};
