import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { LOCAL_REGISTATION_TYPES } from '../../utils/constants';
import LanguageSwitch from '../utils/LanguageSwitch';
import { withRouter } from 'react-router-dom';
import { required, emailValidation, taxCodeValidation, passwordValidation } from '../../utils/validators';
import { getQueryStringValues } from '../../utils/helpers';
import TextFieldRenderer from '../renderers/TextFieldRenderer';
import ContainerHeader from '../../components/layout/ContainerHeader';
import BusinessIcon from '@material-ui/icons/Business';
import { Link, Button } from '@material-ui/core';

class RegisterForm extends Component {
    componentWillUnmount() {
        this.props.resetCredentials();
    }

    isLocalRegisterForm() {
        let qsValues = getQueryStringValues();
        return qsValues && qsValues.mode;
    }

    requireLogin() {
        let qsValues = getQueryStringValues();
        return qsValues && qsValues.mode === LOCAL_REGISTATION_TYPES.EXISTING_ACCOUNT;
    }

    disabledCompanyFields() {
        return this.requireLogin() && !this.props.credentialsValid;
    }

    shouldInactivateUserFields() {
        return !this.isLocalRegisterForm() || (this.requireLogin() && this.props.credentialsValid);
    }

    render() {
        let props = this.props;
        let disabledCompanies = this.disabledCompanyFields();
        const inactivateUser = this.shouldInactivateUserFields();
        const emailValidateRules = this.requireLogin() ? [] : [required, emailValidation];
        const passwordValidateRules = this.requireLogin() ? [] : [required, passwordValidation];
        const companyNameValidationRules = disabledCompanies ? [] : [required];
        const taxCodeValidationRules = disabledCompanies ? [] : [required, taxCodeValidation];
        return (
            <form onSubmit={props.handleSubmit}>
                <div className="register-page">
                    <ContainerHeader
                        iconComponent={BusinessIcon}
                        title={props.translate('Register')}
                        subtitle={props.translate('Company')}
                    />

                    <div className="content">
                        <div className="line subtitle-small">{this.props.translate('PleaseEnterCompanyDetails')}</div>
                        <div className="responsive-form-container">
                            <div className="form-row">
                                <Field
                                    name="email"
                                    component={TextFieldRenderer}
                                    label={props.translate('Email')}
                                    disabled={inactivateUser}
                                    validate={emailValidateRules}
                                />
                            </div>
                            {!inactivateUser && (
                                <React.Fragment>
                                    <div className="form-row">
                                        <Field
                                            name="password"
                                            type="password"
                                            component={TextFieldRenderer}
                                            label={props.translate('Password')}
                                            validate={passwordValidateRules}
                                        />
                                    </div>
                                    {!this.requireLogin() && (
                                        <div className="form-row">
                                            <Field
                                                name="confirmPassword"
                                                type="password"
                                                component={TextFieldRenderer}
                                                label={props.translate('ConfirmPassword')}
                                                validate={[required, passwordValidation]}
                                            />
                                        </div>
                                    )}
                                    {this.requireLogin() && (
                                        <div className="form-row">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => this.props.loginLocalAccount(this.props.email, this.props.password)}
                                            >
                                                {this.props.translate('SignIn')}
                                            </Button>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                            {!disabledCompanies && (
                                <React.Fragment>
                                    <div className="form-row">
                                        <Field
                                            name="companyTaxCode"
                                            component={TextFieldRenderer}
                                            label={props.translate('CompanyTaxCode')}
                                            placeholder={props.translate('ValidVatInEuFormat')}
                                            validate={taxCodeValidationRules}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <Field
                                            name="companyName"
                                            component={TextFieldRenderer}
                                            label={props.translate('CompanyName')}
                                            validate={companyNameValidationRules}
                                        />
                                    </div>
                                    <div className="form-row">
                                        <Button fullWidth variant="contained" color="primary" type="submit">
                                            {this.props.translate('Register')}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="line line4 subtitle-small">
                            <Link underline="none" href="#/login">
                                {this.props.translate('GoBack')}
                            </Link>
                        </div>
                    </div>
                    <div className="footer">
                        <LanguageSwitch />
                    </div>
                </div>
            </form>
        );
    }
}

function validate(values, props) {
    let obj = {};
    if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
        obj.confirmPassword = props.translate('PasswordMismatch');
    }
    return obj;
}

export default reduxForm({ form: 'register', validate: validate })(withRouter(RegisterForm));
