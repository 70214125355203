import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import LanguageSwitch from '../utils/LanguageSwitch';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import { withRouter } from 'react-router-dom';
import ContainerHeader from '../../components/layout/ContainerHeader';
import { HeaderLogo } from '../layout/Logo';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ExternalLoginButtons } from './ExternalLoginButtons';
import { LoginOutlinedButton } from './LoginOutlinedButton';
import { Link } from '@material-ui/core';
import { withTracking } from '../../utils/loggingAppInsights';

class LoginPage extends Component {
    componentWillMount() {
        this.props.resetCredentials();
    }

    componentWillUnmount() {
        this.props.resetMessages();
    }

    onFailure(error) {
        console.log(error);
        this.props.onExternalResponseFail();
    }

    render() {
        const {
            googleApplicationId,
            onGoogleResponseSuccess,
            onExternalResponseFail,
            onExternalLoginStarted,
            microsoftApplicationId,
            onOfficeLoginResponseSuccess,
            isExternalFormSubmitting,
            activeDirectoryTenant,
            localLoginEnabled,
        } = this.props;
        return (
            <div className="login-page">
                <ContainerHeader iconComponent={AccountCircleIcon} title={this.props.translate('SignIn')} />
                <div className="content">
                    <div className="subtitle line line1">{this.props.translate('WelcomeTo')}</div>
                    {this.props.loginPageMessage ? (
                        <div className="line message">{this.props.translate(this.props.loginPageMessage)}</div>
                    ) : (
                        <div className="line line2">
                            <HeaderLogo />
                        </div>
                    )}

                    <div className="line line3 subtitle-small">{this.props.translate('PleaseSignInToProceed')}</div>
                    <div className="login-btns-container">
                        <ExternalLoginButtons
                            googleApplicationId={googleApplicationId}
                            onGoogleResponseSuccess={onGoogleResponseSuccess}
                            onExternalResponseFail={onExternalResponseFail}
                            onExternalLoginStarted={onExternalLoginStarted}
                            microsoftApplicationId={microsoftApplicationId}
                            onOfficeLoginResponseSuccess={onOfficeLoginResponseSuccess}
                            isExternalFormSubmitting={isExternalFormSubmitting}
                            activeDirectoryTenant={activeDirectoryTenant}
                        />

                        {localLoginEnabled && (
                            <LoginOutlinedButton
                                onClick={() => this.props.history.push('/locallogin')}
                                label={this.props.translate('SignInWithAnEmail')}
                            />
                        )}
                    </div>
                    <div className="line line4 subtitle-small">
                        {this.props.translate('NeedAnAccountForCompany')}
                        <br />
                        <Link underline="none" href="#/registerChoice">
                            {this.props.translate('RegisterCompany')}
                        </Link>
                    </div>
                </div>
                <div className="footer">
                    <LanguageSwitch />
                </div>
            </div>
        );
    }
}

export default reduxForm({ form: 'login' })(withTracking(withRouter(withTranslate(LoginPage))));
