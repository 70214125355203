import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import store, { history } from './redux/store';
import './index.scss';
import { App } from './App';

import reportWebVitals from './reportWebVitals';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
