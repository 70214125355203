import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '../components/layout/Page';
import ContainerHeader from '../components/layout/ContainerHeader';
import { withTranslate } from './utils/HigherOrderComponents';
import withTranslations from './layouts/LanguageContainer';
import InviteUserForm from '../components/user/management/InviteUserForm';
import * as userThunks from '../redux/thunks/userThunks';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

class InviteUserContainer extends Component {
    onInvitationSubmit = formData => {
        return this.props.submitUserInvitation(formData);
    };

    componentDidMount() {
        this.props.loadAvailableRoles();
    }

    render() {
        return (
            <Page>
                <div className="invite-user">
                    <ContainerHeader iconComponent={RecentActorsIcon} title={this.props.translate('Invitation')} />
                    {this.props.isInvitationSubmitted ? (
                        <div className="content">
                            <div className="subtitle line line1">{this.props.translate('UserInvitationSuccessfullySent')}</div>
                        </div>
                    ) : (
                        <InviteUserForm onSubmit={this.onInvitationSubmit} availableRoles={this.props.availableRoles} />
                    )}
                </div>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        isInvitationSubmitted: state.invitation.isInvitationSubmitted,
        availableRoles: state.invitation.availableRoles,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        submitUserInvitation: data => dispatch(userThunks.submitUserInvitation(data)),
        loadAvailableRoles: () => dispatch(userThunks.loadAvailableRoles()),
    };
}

const inviteUserContainer = withTranslations(withTranslate(InviteUserContainer));

export default connect(mapStateToProps, mapDispatchToProps)(inviteUserContainer);
