import React from 'react';

let okBtn = props => {
    return (
        <div className="snackbar-button">
            <div onClick={props.remove}>OK</div>
        </div>
    );
};

export const errorNotificationSettings = {
    timeOut: 6000,
    showCloseButton: false,
    component: okBtn,
    icon: <i className="material-icons">error_outline</i>,
};
