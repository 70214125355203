import React from 'react';
import { Field, reduxForm } from 'redux-form';
import LanguageSwitch from '../../utils/LanguageSwitch';
import { withTranslate } from '../../../containers/utils/HigherOrderComponents';
import { required, emailValidation } from '../../../utils/validators';
import TextFieldRenderer from '../../renderers/TextFieldRenderer';
import ContainerHeader from '../../layout/ContainerHeader';
import LockIcon from '@material-ui/icons/Lock';
import { Link, Button } from '@material-ui/core';

const ResetPasswordPage = ({ handleSubmit, translate }) => (
    <div className="reset-password-page">
        <form onSubmit={handleSubmit}>
            <ContainerHeader iconComponent={LockIcon} title={translate('Reset')} subtitle={translate('Password')} />
            <div className="content">
                <div className="line line2 subtitle-small">
                    {translate('PleaseEnterYourRegisteredEmailAndWeWillSendLinkToResetPassword')}
                </div>
                <div className="responsive-form-container">
                    <div className="form-row">
                        <Field
                            component={TextFieldRenderer}
                            validate={[required, emailValidation]}
                            label={translate('Email')}
                            name="email"
                            id="email"
                            type="text"
                        />
                    </div>
                    <div className="form-row">
                        <Button variant="contained" color="primary" fullWidth type="submit">
                            {translate('RequestLink')}
                        </Button>
                    </div>
                </div>
                <div className="line line4 subtitle-small">
                    <Link underline="none" href="#/locallogin">
                        {translate('GoBack')}
                    </Link>
                </div>
            </div>
            <div className="footer">
                <LanguageSwitch />
            </div>
        </form>
    </div>
);

let resetPasswordPage = withTranslate(ResetPasswordPage);

export default reduxForm({ form: 'resetPassword' })(resetPasswordPage);
