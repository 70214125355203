export const LOG_IN_STARTED = 'LOG_IN_STARTED';
export const LOG_IN_SUCCEED = 'LOG_IN_SUCCEED';
export const SET_EXTERNAL_FORM_SUBMITING_FLAG = 'SET_EXTERNAL_FORM_SUBMITING_FLAG';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const LOG_OUT = 'LOG_OUT';
export const REGISTER_STARTED = 'REGISTER_STARTED';
export const REGISTER_SUCCEED = 'REGISTER_SUCCEED';
export const CHECK_IF_AUTHORIZED_STARTED = 'CHECK_IF_AUTHORIZED_STARTED';
export const CHECK_IF_AUTHORIZED_SUCCEED = 'CHECK_IF_AUTHORIZED_SUCCEED';
export const RESET_PASSWORD_SUCCEED = 'RESET_PASSWORD_SUCCEED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const FORGOT_PASSWORD_SUCCEED = 'FORGOT_PASSWORD_SUCCEED';
export const RESEND_ACTIVATION_EMAIL_SUCCEED = 'RESEND_ACTIVATION_EMAIL_SUCCEED';
export const CONFIRM_EMAIL_SUCCEED = 'CONFIRM_EMAIL_SUCCEED';
export const ACTIVATE_BY_SOLTEQ_SUCCEED = 'ACTIVATE_BY_SOLTEQ_SUCCEED';
export const ACTIVATE_BY_SOLTEQ_FAILED = 'ACTIVATE_BY_SOLTEQ_FAILED';

export const CHECK_USER_CREDENTIALS = 'CHECK_USER_CREDENTIALS';
export const CHECK_USER_CREDENTIALS_STARTED = `${CHECK_USER_CREDENTIALS}_STARTED`;
export const CHECK_USER_CREDENTIALS_FAILED = `${CHECK_USER_CREDENTIALS}_FAILED`;
export const CHECK_USER_CREDENTIALS_SUCCEED = `${CHECK_USER_CREDENTIALS}_SUCCEED`;

export const RESET_CREDENTIALS = 'RESET_CREDENTIALS';

export const RESET_MESSAGES = 'RESET_MESSAGES';

export const SET_RETURN_URL = 'SET_RETURN_URL';
export const RESET_RETURN_URL = 'RESET_RETURN_URL';
