import * as actionTypes from '../actions/types/errorDescriptionTypes';

let defaultState = {
    requestId: null,
    errorMessage: null,
};

export default function errorDescriptionReducer(state = defaultState, action) {
    if (action.type === actionTypes.ERROR_DESCRIPTION_FETCHED) {
        return { ...state, error: action.error };
    } else {
        return { ...state };
    }
}
