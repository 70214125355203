import * as actionTypes from './types/notificationActionTypes';

export function notificationMessage(messageType, message, messageKeys, title) {
    return {
        type: actionTypes.NOTIFICATION_MESSAGE,
        messageType,
        message,
        messageKeys,
        title,
    };
}
