import React from 'react';
import styled from 'styled-components';

const StyledIndicator = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.6);

    .label {
        position: absolute;
        top: 30%;
        font-size: 24px;
        text-align: center;
        width: 100%;
        color: ${props => props.theme.primary.main};
    }
`;
const StyledLoader = styled.div`
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 5px solid transparent;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    border-top-color: ${props => props.theme.primary.main};

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 1px solid transparent;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        border-top-color: ${props => props.theme.primary.main};
    }

    &:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        border-radius: 50%;
        border: 3px solid transparent;
        -webkit-animation: spin 1s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
        animation: spin 1s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
        border-top-color: ${props => props.theme.primary.main};
    }
`;

const LabelStyled = styled.div`
    position: absolute;
    top: 30%;
    font-size: 24px;
    text-align: center;
    width: 100%;
    color: ${props => props.theme.primary.main};
`;

const LoadingIndicator = ({ children, label }) => {
    return (
        <StyledIndicator>
            {label && <LabelStyled>{label}</LabelStyled>}

            <StyledLoader />
            {children}
        </StyledIndicator>
    );
};

export default LoadingIndicator;
