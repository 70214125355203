import React from 'react';
import GoogleLogin from 'react-google-login';
import MSLogin from './MSLogin';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import clsx from 'clsx';
import GoogleIcon from '../../images/google-icon.svg';
import MicrosoftIcon from '../../images/ms-icon.svg';
import { makeStyles } from '@material-ui/core';

export const ExternalLoginButtons = withTranslate(
    ({
        translate,
        googleApplicationId,
        onGoogleResponseSuccess,
        onExternalResponseFail,
        onExternalLoginStarted,
        microsoftApplicationId,
        onOfficeLoginResponseSuccess,
        isExternalFormSubmitting,
        activeDirectoryTenant,
    }) => {
        const onFailure = error => {
            console.error(error);
            onExternalResponseFail();
        };

        const classes = useStyles();

        return (
            <React.Fragment>
                {googleApplicationId && (
                    <GoogleLogin
                        render={renderProps => (
                            <div
                                onClick={renderProps.onClick}
                                className={clsx('login-btn', classes.googleLoginBtn, isExternalFormSubmitting && 'disabled')}
                            >
                                {translate('SignInWithGoogle')}
                            </div>
                        )}
                        clientId={googleApplicationId}
                        onSuccess={onGoogleResponseSuccess}
                        onFailure={onFailure}
                        onRequest={onExternalLoginStarted}
                    />
                )}
                {microsoftApplicationId && (
                    <MSLogin
                        clientId={microsoftApplicationId}
                        className={clsx('login-btn', classes.office365LoginBtn, isExternalFormSubmitting && 'disabled')}
                        btnContent={translate('SignInWithOffice')}
                        onOfficeLoginResponseSuccess={onOfficeLoginResponseSuccess}
                        onExternalLoginStarted={onExternalLoginStarted}
                        onExternalResponseFail={onExternalResponseFail}
                        activeDirectoryTenant={activeDirectoryTenant}
                    />
                )}
            </React.Fragment>
        );
    }
);

const useStyles = makeStyles({
    googleLoginBtn: {
        background: `url(${GoogleIcon}) no-repeat 14px 11px`,
    },
    office365LoginBtn: {
        background: `url(${MicrosoftIcon}) no-repeat 14px 11px`,
    },
});
