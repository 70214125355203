import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import InviteUserContainer from '../InviteUserContainer';
import RedirectAfterLogin from './RedirectAfterLogin';
import { connect } from 'react-redux';
import PrivateAdminRoute from '../../components/utils/PrivateAdminRoute';
import UserCodeContainer from './UserCodeContainer';

class AuthorizedLayout extends Component {
    render() {
        return (
            <Switch>
                <PrivateAdminRoute path="/inviteUser" component={InviteUserContainer} loggedIn={this.props.isLoggedIn} />
                <Route path="/userCode" component={UserCodeContainer} />
                <Route path="*" component={RedirectAfterLogin} />
            </Switch>
        );
    }
}

function mapStateToProps(state) {
    return {
        userRoles: state.user.roles,
        isLoggedIn: state.user.isLoggedIn,
    };
}

export default connect(mapStateToProps)(AuthorizedLayout);
