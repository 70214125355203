import * as actionTypes from './types/authorizationActionTypes';

export function logInStarted(googleResponse) {
    return {
        type: actionTypes.LOG_IN_STARTED,
        googleResponse,
    };
}

export function setExternalFormSubmitingFlag(isExternalFormSubmitting) {
    return {
        type: actionTypes.SET_EXTERNAL_FORM_SUBMITING_FLAG,
        isExternalFormSubmitting,
    };
}

export function logInSucceed(data) {
    return {
        type: actionTypes.LOG_IN_SUCCEED,
        data,
    };
}

export function registerRequest(email, grantType, externalToken) {
    return {
        type: actionTypes.REGISTER_REQUEST,
        email,
        grantType,
        externalToken,
    };
}

export function logOut() {
    return {
        type: actionTypes.LOG_OUT,
    };
}

export function registerStarted(registrationType) {
    return {
        type: actionTypes.REGISTER_STARTED,
        registrationType,
    };
}

export function registerSucceed() {
    return {
        type: actionTypes.REGISTER_SUCCEED,
    };
}

export function checkIfAuthorizedStarted() {
    return {
        type: actionTypes.CHECK_IF_AUTHORIZED_STARTED,
    };
}

export function checkIfAuthorizedSucceed(data) {
    return {
        type: actionTypes.CHECK_IF_AUTHORIZED_SUCCEED,
        data,
    };
}

export function resetPasswordSucceed(message) {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCEED,
        message,
    };
}

export function resetPasswordFailed(message) {
    return {
        type: actionTypes.RESET_PASSWORD_FAILED,
        message,
    };
}

export function forgotPasswordSucceed() {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCEED,
    };
}

export function resendActivationEmailSucceed() {
    return {
        type: actionTypes.RESEND_ACTIVATION_EMAIL_SUCCEED,
    };
}

export function confirmEmailSucceed(message) {
    return {
        type: actionTypes.CONFIRM_EMAIL_SUCCEED,
        message,
    };
}

export function activateBySolteqSucceed() {
    return {
        type: actionTypes.ACTIVATE_BY_SOLTEQ_SUCCEED,
    };
}

export function activateBySolteqFailed(message) {
    return {
        type: actionTypes.ACTIVATE_BY_SOLTEQ_FAILED,
        message,
    };
}

export function checkUserCredentialsStarted(loginData) {
    return {
        type: actionTypes.CHECK_USER_CREDENTIALS_STARTED,
        loginData,
    };
}

export function checkUserCredentialsSucceed(data) {
    return {
        type: actionTypes.CHECK_USER_CREDENTIALS_SUCCEED,
        data,
    };
}

export function checkUserCredentialsFailed(messageKey) {
    return {
        type: actionTypes.CHECK_USER_CREDENTIALS_FAILED,
        messageKey,
    };
}

export function resetCredentials() {
    return {
        type: actionTypes.RESET_CREDENTIALS,
    };
}

export function resetMessages() {
    return {
        type: actionTypes.RESET_MESSAGES,
    };
}

export function setReturnUrl(returnUrl) {
    return {
        type: actionTypes.SET_RETURN_URL,
        returnUrl,
    };
}

export function resetReturnUrl() {
    return {
        type: actionTypes.RESET_RETURN_URL,
    };
}
