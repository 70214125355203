/*eslint no-unused-vars: "off"*/
/* global authFetch:true */
import * as actions from '../actions/authorizationActions';
import * as routes from '../../api/routes';
import { RETURN_URL } from '../../utils/constants';
import { fetchWrapper } from '../../utils/fetchWrapper';
import { defaultHeaders } from './defaults';

export function checkIfAuthorized() {
    return dispatch => {
        return fetchWrapper(routes.ISAUTHORIZED_URL, dispatch, {
            method: 'GET',
            headers: defaultHeaders,
        })
            .withSuccessHandler(data => {
                const returnUrl = localStorage.getItem(RETURN_URL);
                data.returnUrl = returnUrl;
                dispatch(actions.checkIfAuthorizedSucceed(data));
            })
            .start();
    };
}

export function checkUserCredentials(model, on401) {
    return dispatch => {
        const returnUrl = localStorage.getItem(RETURN_URL);
        model.returnUrl = returnUrl;

        return fetchWrapper(routes.CHECK_USER_CREDENTIALS, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(model),
        })
            .withStartedHandler(() => dispatch(actions.checkUserCredentialsStarted(model)))
            .withSuccessHandler(data => dispatch(actions.checkUserCredentialsSucceed(data)))
            .withCustomErrorHandler(401, on401)
            .start();
    };
}

export function logIn(companyTaxCode) {
    return (dispatch, getState) => {
        let state = getState();
        const returnUrl = localStorage.getItem(RETURN_URL);
        let model = state.user.loginData;
        model.returnUrl = returnUrl;
        model.companyTaxCode = companyTaxCode;
        return fetchWrapper(routes.LOGIN_URL, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            credentials: 'same-origin',
            body: JSON.stringify(model),
        })
            .withStartedHandler(() => dispatch(actions.logInStarted(model)))
            .withSuccessHandler(data => {
                localStorage.removeItem(RETURN_URL);
                dispatch(actions.logInSucceed(data));
            })
            .start();
    };
}

export function resetPassword(data) {
    return dispatch => {
        return fetchWrapper(routes.RESET_PASSWORD, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(data),
        })
            .withSuccessHandler(() => dispatch(actions.resetPasswordSucceed('YourPasswordHasBeenChanged')))
            .start();
    };
}

export function forgotPassword(data) {
    return dispatch => {
        return fetchWrapper(routes.FORGOT_PASSWORD, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(data),
        })
            .withSuccessHandler(() => dispatch(actions.forgotPasswordSucceed()))
            .start();
    };
}

export function resendActivationEmailHandleSubmit(data) {
    return dispatch => {
        return fetchWrapper(routes.RESEND_ACTIVATION_EMAIL, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(data),
        })
            .withSuccessHandler(() => dispatch(actions.resendActivationEmailSucceed()))
            .start();
    };
}

export function confirmEmail(data, translate) {
    return dispatch => {
        return fetchWrapper(routes.CONFIRM_EMAIL, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(data),
        })
            .withSuccessHandler(response => {
                dispatch(actions.confirmEmailSucceed(translate(response.messageKey)));
            })
            .start();
    };
}

export function activateBySolteq(data, translate) {
    return dispatch => {
        return fetchWrapper(routes.ACTIVATE_BY_SOLTEQ, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(data),
        })
            .withSuccessHandler(() => dispatch(actions.activateBySolteqSucceed()))
            .withDefaultErrorHandler(async response => {
                const responseData = await response.json();
                let failMessageKey = responseData[0];
                dispatch(actions.activateBySolteqFailed(translate(failMessageKey)));
            })
            .start();
    };
}

export function register(model) {
    return dispatch => {
        const returnUrl = localStorage.getItem(RETURN_URL);
        model.returnUrl = returnUrl;
        return fetchWrapper(routes.REGISTER_URL, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(model),
        })
            .withStartedHandler(() => dispatch(actions.registerStarted(model.registrationType)))
            .withSuccessHandler(() => dispatch(actions.registerSucceed()))
            .start();
    };
}
