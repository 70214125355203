import { fetchWrapper } from '../../utils/fetchWrapper';
import { NOTIFICATION_MESSAGE_TYPES } from '../../utils/constants';
import { defaultHeaders } from './defaults';
import * as routes from '../../api/routes';
import * as subscriptionActions from '../actions/subscriptionActions';
import * as notificationActions from '../actions/notificationActions';

export function loadAvailableApplications() {
    return dispatch => {
        return fetchWrapper(routes.FETCH_AVAILABLE_APPLICATIONS, dispatch, {
            method: 'GET',
            headers: defaultHeaders,
        })
            .withSuccessHandler(applications => dispatch(subscriptionActions.availableApplicationsFetched(applications)))
            .withDefaultErrorHandler(() => {
                dispatch(notificationActions.notificationMessage(NOTIFICATION_MESSAGE_TYPES.FAIL, undefined, 'ServiceUnavailable'));
                dispatch(subscriptionActions.availableApplicationsFetched([]));
            })
            .start();
    };
}
