import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import RegisterForm from '../../components/authorization/RegisterForm';
import RegisterChoice from '../../components/authorization/RegisterChoice';
import * as authorizationThunks from '../../redux/thunks/authorizationThunks';
import { Route } from 'react-router-dom';
import { resetCredentials, setExternalFormSubmitingFlag } from '../../redux/actions/authorizationActions';
import { REGISTRATION_TYPES, LOCAL_REGISTATION_TYPES, GRANT_TYPES } from '../../utils/constants';
import ResendActivationEmailPage from '../../components/user/resendActivationEmail/ResendActivationEmailPage';
import ConfirmEmail from '../../components/user/confirmEmail/ConfirmEmail';
import ActivateBySolteq from '../../components/user/ActivateBySolteq';
import AlmostDone from '../../components/user/AlmostDone';
import ConfirmInvitedUser from '../../components/user/ConfirmInvitedUser';
import { SubmissionError } from 'redux-form';
import queryString from 'querystring';
import * as userThunks from '../../redux/thunks/userThunks';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import { getQueryStringValues } from '../../utils/helpers';
import BusinessIcon from '@material-ui/icons/Business';

class RegisterContainer extends Component {
    onRegisterSubmit = formData => {
        if (formData.password !== formData.confirmPassword && formData.registerFormMode === LOCAL_REGISTATION_TYPES.NEW_ACCOUNT) {
            throw new SubmissionError({
                confirmPassword: this.props.translate('PasswordMismatch'),
                _error: 'PasswordMissmatch',
            });
        }
        let qsValues = getQueryStringValues();
        formData.userRegistrationRule = qsValues && qsValues.mode ? qsValues.mode : 'none';
        if (!formData.externalToken) {
            formData.registrationType = REGISTRATION_TYPES.INTERNAL;
            formData.grantType = GRANT_TYPES.LOCAL;
        } else {
            formData.registrationType = REGISTRATION_TYPES.EXTERNAL;
        }

        return this.props.register(formData).then(this.redirectAfterRegistaration);
    };

    onExternalRegister401Error = () => this.props.history.push('/register');

    redirectAfterRegistaration = response => {
        if (!response) return;
        let qsValues = getQueryStringValues();
        let registerFormMode = qsValues && qsValues.mode;
        if (this.props.registrationType === REGISTRATION_TYPES.EXTERNAL || registerFormMode === LOCAL_REGISTATION_TYPES.EXISTING_ACCOUNT) {
            this.props.history.push('/confirmEmail');
        } else {
            this.props.history.push('/registrationAlmostDone');
        }
    };

    onGoogleRegisterResponseSuccess = response => {
        let loginData = {
            idToken: response.tokenId,
            email: response.profileObj.email,
            registerType: REGISTRATION_TYPES.EXTERNAL,
            grantType: GRANT_TYPES.GOOGLE,
        };

        this.props
            .checkUserCredentials(loginData, this.onExternalRegister401Error)
            .then(response => response && this.props.history.push('/register'));
    };

    onOfficeRegisterResponseSuccess = (email, token) => {
        let loginData = {
            idToken: token,
            registerType: REGISTRATION_TYPES.EXTERNAL,
            grantType: GRANT_TYPES.OFFICE,
            email,
        };
        this.props
            .checkUserCredentials(loginData, this.onExternalRegister401Error)
            .then(response => response && this.props.history.push('/register'));
    };

    loginLocalAccount = (email, password) => {
        const data = {
            grantType: GRANT_TYPES.LOCAL,
            registerType: REGISTRATION_TYPES.INTERNAL,
            email,
            password,
        };
        return this.props.checkUserCredentials(data);
    };

    passwordUserInvitation = formData => {
        if (formData.password !== formData.confirmPassword) {
            throw new SubmissionError({
                confirmPassword: 'Password missmatch',
                _error: 'PasswordMissmatch',
            });
        }
        let qs = this.props.location.search.substring(1);
        const values = queryString.parse(qs);
        let data = {
            userId: values.userId,
            code: values.code,
            type: values.type,
            newPassword: formData.password,
            companyTaxCode: values.companyTaxCode,
        };

        return this.props.confirmInvitedUser(data, false);
    };

    noPasswordUserInvitation = () => {
        let qs = this.props.location.search.substring(1);
        const values = queryString.parse(qs);
        let data = {
            userId: values.userId,
            code: values.code,
            type: values.type,
            companyTaxCode: values.companyTaxCode,
        };

        let inactiveCompany = this.props.location.search.indexOf('inactiveCompany') !== -1;

        return this.props.confirmInvitedUser(data, inactiveCompany);
    };

    resendActivationEmailHandleSubmit = formData => {
        this.props
            .resendActivationEmailHandleSubmit(formData)
            .then(response => response && this.props.history.push('/registrationAlmostDone'));
    };

    render() {
        return (
            <React.Fragment>
                {this.props.localLoginEnabled && (
                    <Route
                        path="/register"
                        exact
                        render={() => (
                            <RegisterForm
                                onSubmit={this.onRegisterSubmit}
                                initialValues={this.props.initialValues}
                                loginLocalAccount={this.loginLocalAccount}
                                registerFormMode={this.props.registerFormMode}
                                email={this.props.email}
                                resetCredentials={this.props.resetCredentials}
                                password={this.props.password}
                                credentialsValid={this.props.credentialsValid}
                                translate={this.props.translate}
                            />
                        )}
                    />
                )}
                <Route
                    path="/registerChoice"
                    exact
                    render={() => (
                        <RegisterChoice
                            onGoogleResponseSuccess={this.onGoogleRegisterResponseSuccess}
                            onOfficeLoginResponseSuccess={this.onOfficeRegisterResponseSuccess}
                            onExternalLoginStarted={this.props.onExternalLoginStarted}
                            onExternalResponseFail={this.props.onExternalResponseFail}
                            microsoftApplicationId={this.props.microsoftApplicationId}
                            googleApplicationId={this.props.googleApplicationId}
                            localLoginEnabled={this.props.localLoginEnabled}
                        />
                    )}
                />

                <Route
                    path="/confirmEmail"
                    exact
                    render={() => (
                        <ConfirmEmail
                            confirmEmail={this.props.confirmEmail}
                            history={this.props.history}
                            isConfirmEmailSucceed={this.props.isConfirmEmailSucceed}
                            inactiveCompany={this.props.inactiveCompany}
                            translate={this.props.translate}
                        />
                    )}
                />
                <Route
                    path="/confirmInvitedUser"
                    exact
                    render={() => (
                        <ConfirmInvitedUser
                            onSubmit={this.passwordUserInvitation}
                            noPasswordUserInvitation={this.noPasswordUserInvitation}
                            isConfirmInvitedUserSucceed={this.props.isConfirmInvitedUserSucceed}
                            inactiveCompany={this.props.inactiveCompany}
                            passwordValidation={this.passwordValidation}
                        />
                    )}
                />
                <Route
                    path="/activateBySolteq"
                    exact
                    render={() => (
                        <ActivateBySolteq
                            activateBySolteq={this.props.activateBySolteq}
                            isActivateBySolteqSucceed={this.props.isActivateBySolteqSucceed}
                            activateBySolteqFailedMessage={this.props.activateBySolteqFailedMessage}
                        />
                    )}
                />
                <Route
                    path="/resendActivationEmail"
                    exact
                    render={() => <ResendActivationEmailPage onSubmit={this.resendActivationEmailHandleSubmit} />}
                />
                <Route
                    path="/registrationAlmostDone"
                    exact
                    render={() => (
                        <AlmostDone
                            title="Register"
                            subtitle="Company"
                            iconComponent={BusinessIcon}
                            textLines={['WeSentYouALinkToVerifyYourEmailAddress', 'PleaseCheckYourInbox']}
                        />
                    )}
                />
            </React.Fragment>
        );
    }
}

function getFromForm(state, field) {
    return state.form && state.form.register && state.form.register.values && state.form.register.values[field];
}

function getRegisterFormInitialValues(user) {
    return user.loginData
        ? {
              email: user.loginData.email,
              grantType: user.loginData.grantType,
              externalToken: user.loginData.idToken,
          }
        : {
              grantType: GRANT_TYPES.LOCAL,
              registrationType: REGISTRATION_TYPES.INTERNAL,
          };
}

function mapStateToProps(state, ownProps) {
    return {
        registerFormMode: state.user.registerFormMode,
        registrationType: state.user.registrationType,
        initialValues: getRegisterFormInitialValues(state.user),
        isConfirmEmailSucceed: state.user.isConfirmEmailSucceed,
        isActivateBySolteqSucceed: state.user.isActivateBySolteqSucceed,
        isConfirmInvitedUserSucceed: state.user.isConfirmInvitedUserSucceed,
        inactiveCompany: state.user.inactiveCompany,
        activateBySolteqFailedMessage: state.user.activateBySolteqFailedMessage,
        email: getFromForm(state, 'email'),
        password: getFromForm(state, 'password'),
        credentialsValid: state.user.credentialsValid,
        microsoftApplicationId: ownProps.microsoftApplicationId,
        localLoginEnabled: ownProps.localLoginEnabled,
        googleApplicationId: ownProps.googleApplicationId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        register: model => dispatch(authorizationThunks.register(model)),
        confirmEmail: (data, translate) => dispatch(authorizationThunks.confirmEmail(data, translate)),
        resendActivationEmailHandleSubmit: data => dispatch(authorizationThunks.resendActivationEmailHandleSubmit(data)),
        confirmInvitedUser: (data, inactiveCompany) => dispatch(userThunks.confirmInvitedUser(data, inactiveCompany)),
        activateBySolteq: (data, translate) => dispatch(authorizationThunks.activateBySolteq(data, translate)),
        resetCredentials: () => dispatch(resetCredentials()),
        checkUserCredentials: (data, on401) => dispatch(authorizationThunks.checkUserCredentials(data, on401)),
        onExternalLoginStarted: () => dispatch(setExternalFormSubmitingFlag(true)),
        onExternalResponseFail: () => dispatch(setExternalFormSubmitingFlag(false)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslate(RegisterContainer)));
