import React from 'react';
import { OutlinedSelect } from '../SolteqComponents';

export const SelectFieldRenderer = ({ input, label, meta: { touched, error }, children, selectedValue, ...custom }) => (
    <OutlinedSelect
        label={label}
        errorText={touched && error}
        {...input}
        helperText={touched ? error : ''}
        error={touched && !!error}
        onChange={value => input.onChange(value)}
        children={children}
        {...custom}
    />
);
