import React from 'react';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';
import ReactCodeInput from 'react-verification-code-input';

const StyledAuthContainer = styled.div`
    .styles_react-code-input__CRulA > input:focus + input {
        border-left: solid 1px #a8adb7;
    }
    .styles_react-code-input-container__tpiKG {
        width: revert !important;
    }
    .styles_react-code-input__CRulA {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;

        > input {
            margin: 8px;
            border-radius: 8px;
            border-right: solid 1px #a8adb7;
            border: solid 1px #a8adb7;
            caret-color: revert;
            font-family: revert;
        }
    }
`;
function UserCodeForm({ handleSubmit }) {
    return (
        <form onSubmit={handleSubmit}>
            <Field
                name="code"
                component={({ input: { value, onChange } }) => {
                    return (
                        <StyledAuthContainer>
                            <ReactCodeInput values={value} fields={5} onChange={onChange} />
                        </StyledAuthContainer>
                    );
                }}
            />
        </form>
    );
}

export default reduxForm({ form: 'userCode', enableReinitialize: true })(UserCodeForm);
