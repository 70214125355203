export const ACCOUNT_URL = '/account';
export const REGISTER_URL = ACCOUNT_URL + '/register';
export const ISAUTHORIZED_URL = ACCOUNT_URL + '/isAuthorized';
export const CONFIRM_EMAIL = ACCOUNT_URL + '/confirmEmail';
export const ACTIVATE_BY_SOLTEQ = ACCOUNT_URL + '/activateBySolteq';
export const FORGOT_PASSWORD = ACCOUNT_URL + '/forgotPassword';
export const RESEND_ACTIVATION_EMAIL = ACCOUNT_URL + '/resendActivationEmail';
export const RESET_PASSWORD = ACCOUNT_URL + '/resetPassword';
export const LOGIN_URL = ACCOUNT_URL + '/login';
export const CHECK_USER_CREDENTIALS = ACCOUNT_URL + '/checkUserCredentials';

export const USERS_URL = '/api/users';
export const CONFIRM_INVITED_USER_URL = USERS_URL + '/confirmInvitedUser';
export const SUBMIT_USER_INVITATION_URL = USERS_URL + '/internalInvite';
export const FETCH_AVAILABLE_ROLES = USERS_URL + '/roles';

const APPLICATIONS_URL = '/api/applications';
export const FETCH_AVAILABLE_APPLICATIONS = APPLICATIONS_URL + '/available';

export const GET_CONFIGURATION = 'api/configuration';
export const GET_TRANSLATIONS = GET_CONFIGURATION + '/translations';

export const ERRORS_URL = '/api/errors';

export const DEVICE_CODE = '/device';
