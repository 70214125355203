import * as actionTypes from '../actions/types/userActionTypes';
import * as routes from '../../api/routes';
import { conventionFetch, fetchWrapper } from '../../utils/fetchWrapper';
import * as invitationActions from '../actions/invitationActions';
import * as notificationActions from '../actions/notificationActions';
import { NOTIFICATION_MESSAGE_TYPES } from '../../utils/constants';
import { defaultHeaders } from './defaults';

export function confirmInvitedUser(data, inactiveCompany) {
    return dispatch => {
        let prepareParams = {
            succeedActionExtraParams: {
                message: 'YourInvitationHasBeenConfirmed',
                inactiveCompany: inactiveCompany,
            },
        };
        return conventionFetch(routes.CONFIRM_INVITED_USER_URL, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(data),
        })
            .withActionType(actionTypes.CONFIRM_INVITED_USER)
            .prepare(prepareParams)
            .start();
    };
}

export function submitUserInvitation(data) {
    return dispatch => {
        const enrichedData = {
            ...data,
            externalId: '',
        };

        return fetchWrapper(routes.SUBMIT_USER_INVITATION_URL, dispatch, {
            method: 'POST',
            headers: defaultHeaders,
            body: JSON.stringify(enrichedData),
        })
            .withSuccessHandler(() => dispatch(invitationActions.invitationSubmitted()))
            .withDefaultErrorHandler(() =>
                dispatch(notificationActions.notificationMessage(NOTIFICATION_MESSAGE_TYPES.FAIL, undefined, 'ErrorTryPerformActionAgain'))
            )
            .start();
    };
}

export function loadAvailableRoles() {
    return dispatch => {
        return fetchWrapper(routes.FETCH_AVAILABLE_ROLES, dispatch, {
            method: 'GET',
            headers: defaultHeaders,
        })
            .withSuccessHandler(roles => dispatch(invitationActions.rolesFetched(roles)))
            .withDefaultErrorHandler(() =>
                dispatch(notificationActions.notificationMessage(NOTIFICATION_MESSAGE_TYPES.FAIL, undefined, 'ServiceUnavailable'))
            )
            .start();
    };
}
