import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ReactSelect from 'react-select';
import store from '../redux/store';
import { getTranslate } from 'react-localize-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

class DefaultSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValue: props.value ? props.value : '',
            opened: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            opened: false,
            selectedValue: nextProps.value,
        });
    }

    onChange(event) {
        this.setState({
            ...this.state,
            opened: false,
            selectedValue: event.target.value,
        });
        this.props.onChange && this.props.onChange(event.target.value);
    }

    onOpen() {
        this.setState({
            ...this.state,
            opened: true,
        });
    }

    onClose() {
        this.setState({
            ...this.state,
            opened: false,
        });
    }

    render() {
        const { fieldSetClassName, name, error, className, options = [], errorText, helperText, label, ...restProps } = this.props;
        const { opened } = this.state;

        return (
            <FormControl error={error} className={clsx(fieldSetClassName, opened && 'opened')}>
                <DefaultSelectInputLabel name={name} error={error} label={label} menuOpened={opened} />
                <Select
                    className={clsx(className)}
                    {...restProps}
                    value={this.state.selectedValue}
                    onChange={e => this.onChange(e)}
                    open={this.state.opened}
                    onOpen={e => this.onOpen(e)}
                    onClose={e => this.onClose(e)}
                    inputProps={{
                        name: name,
                        id: name,
                    }}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                    }}
                >
                    {options.map(item => (
                        <MenuItem value={item.value} key={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}
const DefaultSelectInputLabel = ({ name, error, label, menuOpened }) => {
    const classes = useDefaultSelectInputLabelStyles();

    return (
        <InputLabel htmlFor={name} error={error} className={clsx(classes.label, menuOpened && classes.labelWhenMenuOpened)}>
            {label}
        </InputLabel>
    );
};

const useDefaultSelectInputLabelStyles = makeStyles(theme => ({
    labelWhenMenuOpened: {},
    label: {
        paddingLeft: '14px',
        zIndex: 2,
        left: '6px',
        top: '-6px',
        display: 'inline-block',
        padding: '0 4px',
        background: theme.palette.common.white,
    },
}));

export const OutlinedSelect = props => {
    const input = <OutlinedInput labelWidth={10} name={props.name} id={props.name} />;

    return <DefaultSelect input={input} fieldSetClassName="outlined-select" {...props} />;
};

class AutoComplete extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.isMulti) {
            this.state = {
                translate: getTranslate(store.getState().locale),
                value: props.value != null ? props.options.find(o => o.value === props.value) : [],
            };
        } else {
            this.state = {
                translate: getTranslate(store.getState().locale),
                value: props.value != null ? props.value : [],
            };
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.isMulti) {
            this.setState({
                ...this.state,
                value: nextProps.value != null ? nextProps.options.find(o => o.value === nextProps.value) : [],
            });
        } else {
            this.setState({
                ...this.state,
                value: nextProps.value != null ? nextProps.value : [],
            });
        }
    }

    onMenuOpen() {
        this.setState({
            ...this.state,
            isOpen: true,
        });
    }

    onMenuClose() {
        this.setState({
            ...this.state,
            isOpen: false,
        });
    }

    onChange(newValue) {
        this.setState(
            {
                ...this.state,
                value: newValue,
            },
            () => {
                this.props.onChange && this.props.onChange(newValue);
            }
        );
    }

    hasValue() {
        return Array.isArray(this.props.value) ? this.props.value.length > 0 : !!this.props.value;
    }

    render() {
        const props = this.props;

        return (
            <div
                className={
                    ` ${this.props.variant} ` + (this.state.isOpen || this.hasValue() ? 'opened ' : '') + (this.props.className || '')
                }
            >
                <label>{props.label}</label>
                <ReactSelect
                    formatCreateLabel={inputValue => `${this.state.translate('Create')} "${inputValue}"`}
                    options={props.options}
                    value={this.state.value}
                    ref={props.getRef}
                    onChange={value => this.onChange(value)}
                    isMulti={props.isMulti}
                    onMenuOpen={() => this.onMenuOpen()}
                    onMenuClose={() => this.onMenuClose()}
                />
                <svg
                    className="MuiSvgIcon-root-59 MuiSelect-icon-295"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                >
                    <path d="M7 10l5 5 5-5z" />
                </svg>
            </div>
        );
    }
}

export const FilledAutoComplete = props => {
    return <AutoComplete {...props} variant="filled" />;
};
