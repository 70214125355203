import queryString from 'querystring';

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const throwIfRequestFailed = data => {
    const status = data.status;
    if ((status >= 200 && status < 300) || status === 304) {
        return;
    }
    if (status === 401) {
        throw Number(401);
    }
    throw data;
};

export const formatFloat = price => {
    return parseFloat(price || 0).toFixed(2);
};

export const isEmail = email => {
    var regex = /\S+@\S+\.\S+/;
    return regex.test(email);
};

export const sanitizeAddress = address => {
    if (!address) {
        return 'Landing Page';
    }

    address = address.replace('#/', '');

    const segments = address.split('/');

    let sanitizedAddress = segments[0] + '/';
    for (let segment of segments.slice(1)) {
        if (segment.includes('-') || isEmail(segment) || !Number.isNaN(+segment)) {
            break;
        }

        sanitizedAddress += segment + '/';
    }

    return sanitizedAddress;
};

export const getQueryStringValues = function() {
    let href = window.location.href;
    let qs = href.split('?');
    if (qs[1]) {
        return queryString.parse(qs[1]);
    }
    return null;
};

export const parseToNumber = value => {
    value = value || 0;
    return !isNaN(value) ? parseInt(value) : 0;
};
