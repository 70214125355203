import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { Redirect, withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import NonAuthorizedLayout from './NonAuthorizedLayout';
import AuthorizedLayout from './AuthorizedLayout';
import NotificationContainer from '../notifications/NotificationContainer';
import PrivateRoute from '../../components/utils/PrivateRoute';
import * as authorizationThunks from '../../redux/thunks/authorizationThunks';
import * as authorizationActions from '../../redux/actions/authorizationActions';
import * as configurationsThunks from '../../redux/thunks/configurationsThunks';
import withTranslations from './LanguageContainer';
import { getQueryStringValues } from '../../utils/helpers';
import { ErrorPage } from './ErrorPageContainer';
import { getAppInsights } from '../../utils/loggingAppInsights';

class LayoutContainer extends Component {
    componentDidCatch(error, errorInfo) {
        const appInsights = getAppInsights();
        appInsights?.trackException(error, null, { info: errorInfo }, null, 4);
    }
    async componentDidMount() {
        await this.props.getConfiguration();

        const pathsWithoutCheckingAuthorization = [
            '/newpassword',
            '/resetpassword',
            '/confirmemail',
            '/confirminviteduser',
            '/activatebysolteq',
            '/resendactivationemail',
            '/registrationalmostdone',
            '/resetpasswordalmostdone',
            '/login',
            '/logout',
        ];

        const currentLocation = this.props.location.pathname.toLowerCase();

        let qs = getQueryStringValues();

        if (currentLocation === '/usercode') {
            this.props.setReturnUrl(`${window.location.href}`);
        }

        if (currentLocation === '/login' && qs && qs.returnUrl) {
            this.props.setReturnUrl(qs.returnUrl);
            this.props.history.push('/login');
        }

        if (!pathsWithoutCheckingAuthorization.some(p => p === currentLocation)) {
            this.props.checkIfAuthorized();
        }
    }

    render() {
        const showAuthorizedLayout = this.props.isLoggedIn && this.props.configurationLoaded;
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/error" render={() => <ErrorPage />} />
                    <Route>
                        <React.Fragment>
                            <NotificationContainer translate={this.props.translate} />
                            {showAuthorizedLayout && (
                                <PrivateRoute path="/" component={AuthorizedLayout} loggedIn={this.props.isLoggedIn} />
                            )}
                            {!showAuthorizedLayout && <NonAuthorizedLayout translate={this.props.translate} />}
                            {!this.props.isLoggedIn && this.props.loginChecked && <Redirect to="/login" />}
                        </React.Fragment>
                    </Route>
                </Switch>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.user.isLoggedIn,
        loginChecked: state.user.loginChecked,
        translate: getTranslate(state.locale),
        configurationLoaded: state.configurationsData.configurationLoaded,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        checkIfAuthorized: () => dispatch(authorizationThunks.checkIfAuthorized()),
        getConfiguration: () => dispatch(configurationsThunks.getConfiguration()),
        setReturnUrl: returnUrl => dispatch(authorizationActions.setReturnUrl(returnUrl)),
        resetReturnUrl: () => dispatch(authorizationActions.setReturnUrl()),
    };
}

export default withRouter(withTranslations(connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)));
