import * as actionTypes from './types/userActionTypes';

export function rolesFetched(roles) {
    return {
        type: actionTypes.GET_ROLES_SUCCEED,
        availableRoles: roles,
    };
}
export function invitationSubmitted() {
    return {
        type: actionTypes.SUBMIT_USER_INVITATION_SUCCEED,
    };
}
