import * as actionTypes from '../actions/types/userActionTypes';

let defaultState = {
    isInvitationSubmitted: false,
    availableRoles: [],
};

export default function userInvitationReducer(state = defaultState, action) {
    switch (action.type) {
        case actionTypes.SUBMIT_USER_INVITATION_SUCCEED: {
            return { ...state, isInvitationSubmitted: true };
        }
        case actionTypes.GET_ROLES_SUCCEED: {
            return { ...state, availableRoles: action.availableRoles };
        }
        default: {
            return { ...state };
        }
    }
}
