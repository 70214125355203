import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@appgeist/react-select-material-ui';

const useStyles = makeStyles(theme => {
    return {
        root: props => {
            return {
                width: '100%',
                '& .css-1hb7zxy-IndicatorsContainer': {
                    '& span, & svg': {
                        display: 'none',
                    },
                    '&::after': {
                        content: "'arrow_drop_down'",
                        fontFamily: 'Material Icons',
                        width: '10px',
                        height: '10px',
                        fontSize: '24px',
                        display: 'block',
                        position: 'relative',
                        right: '22px',
                        top: '-4px',
                        opacity: props.disabled ? 0.4 : 0.8,
                    },
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    transform: props.value ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 16px) scale(1)',
                },
                '& legend': {
                    display: props.value ? 'block' : 'none',
                },
                '& .Mui-focused legend': {
                    display: 'block',
                },
                '& .MuiInputLabel-outlined': {
                    ...(props.disabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}),
                },
                '& .MuiInputLabel-outlined.Mui-focused': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                },
                '& .MuiInputBase-input': {
                    paddingTop: props.isMulti ? '5px' : '10px',
                    paddingBottom: props.isMulti ? '5px' : '10px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    ...(props.disabled ? { borderColor: 'rgba(0, 0, 0, 0.26)' } : {}),
                },
                '& .MuiChip-root': {
                    ...(props.disabled ? { opacity: 0.75 } : {}),
                },
            };
        },
    };
});

const AutoComplete = ({ value, disabled, fullWidth, isMulti, ...props }) => {
    const classes = useStyles({ fullWidth, value, isMulti, disabled });
    return (
        <Select
            value={value}
            isMulti={isMulti}
            className={`${classes.root} ${classes.MuiInputLabel}`}
            InputProps={{
                classes: classes.MuiInputBase,
            }}
            isDisabled={disabled}
            {...props}
        />
    );
};

export default AutoComplete;
