import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authorizationThunks from '../../redux/thunks/authorizationThunks';
import { Route } from 'react-router-dom';
import { setExternalFormSubmitingFlag, resetCredentials, resetMessages } from '../../redux/actions/authorizationActions';
import { GRANT_TYPES, REGISTRATION_TYPES } from '../../utils/constants';
import NewPasswordPage from '../../components/user/resetPassword/NewPasswordPage';
import ResetPasswordPage from '../../components/user/forgotPassword/ResetPasswordPage';
import AlmostDone from '../../components/user/AlmostDone';
import { SubmissionError } from 'redux-form';
import queryString from 'querystring';
import * as userThunks from '../../redux/thunks/userThunks';
import LocalLoginForm from '../../components/authorization/LocalLoginForm';
import ChooseCompany from '../../components/user/chooseCompany/ChooseCompany';
import LoginPage from '../../components/authorization/LoginPage';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import LockIcon from '@material-ui/icons/Lock';

class LoginContainer extends Component {
    onLocalLoginSubmit = formData => {
        formData.grantType = GRANT_TYPES.LOCAL;
        formData.registerType = REGISTRATION_TYPES.INTERNAL;
        return this.props.checkUserCredentials(formData).then(response => response && this.props.history.push('/chooseCompany'));
    };

    onExternalRegister401Error = () => this.props.history.push('/register');

    onGoogleResponseSuccess = response => {
        let loginData = {
            idToken: response.tokenId,
            email: response.profileObj.email,
            registerType: REGISTRATION_TYPES.EXTERNAL,
            grantType: GRANT_TYPES.GOOGLE,
        };
        this.props.checkUserCredentials(loginData, this.onExternalRegister401Error).then(this.redirectAfterCheckingCredentials);
    };

    onOfficeLoginResponseSuccess = (email, token) => {
        let loginData = {
            idToken: token,
            registerType: REGISTRATION_TYPES.EXTERNAL,
            grantType: GRANT_TYPES.OFFICE,
            email,
        };
        this.props.checkUserCredentials(loginData, this.onExternalRegister401Error).then(this.redirectAfterCheckingCredentials);
    };

    redirectAfterCheckingCredentials = response => response && this.props.history.push('/chooseCompany');

    resetPasswordHandleSubmit = formData => {
        if (formData.password !== formData.confirmPassword) {
            throw new SubmissionError({
                confirmPassword: 'Password missmatch',
                _error: 'PasswordMissmatch',
            });
        }
        let qs = this.props.location.search.substring(1);
        const values = queryString.parse(qs);
        formData.userId = values.userId;
        formData.code = values.code;
        return this.props
            .resetPasswordHandleSubmit(formData, this.props.translate)
            .then(response => response && this.props.history.push('/locallogin'));
    };

    forgotPasswordHandleSubmit = formData => {
        this.props.forgotPasswordHandleSubmit(formData).then(response => response && this.props.history.push('/resetPasswordAlmostDone'));
    };

    render() {
        return (
            <React.Fragment>
                <Route
                    path="/login"
                    exact
                    render={() => (
                        <LoginPage
                            handleSubmit={this.onLocalLoginSubmit}
                            onGoogleResponseSuccess={this.onGoogleResponseSuccess}
                            onExternalResponseFail={this.props.onExternalResponseFail}
                            onOfficeLoginResponseSuccess={this.onOfficeLoginResponseSuccess}
                            loggedIn={this.props.loggedIn}
                            isExternalFormSubmitting={this.props.isExternalFormSubmitting}
                            onExternalLoginStarted={this.props.onExternalLoginStarted}
                            microsoftApplicationId={this.props.microsoftApplicationId}
                            localLoginEnabled={this.props.localLoginEnabled}
                            googleApplicationId={this.props.googleApplicationId}
                            checkUserCredentials={this.props.checkUserCredentials}
                            userCompanies={this.props.userCompanies}
                            loginPageMessage={this.props.loginPageMessage}
                            resetCredentials={this.props.resetCredentials}
                            logIn={this.props.logIn}
                            resetMessages={this.props.resetMessages}
                            activeDirectoryTenant={this.props.activeDirectoryTenant}
                        />
                    )}
                />
                {this.props.localLoginEnabled && (
                    <Route
                        path="/locallogin"
                        exact
                        render={() => (
                            <LocalLoginForm
                                onSubmit={this.onLocalLoginSubmit}
                                loginFormMessage={this.props.loginFormMessage}
                                resetCredentials={this.props.resetCredentials}
                                resetMessages={this.props.resetMessages}
                            />
                        )}
                    />
                )}
                <Route
                    path="/chooseCompany"
                    exact
                    render={() => (
                        <ChooseCompany logIn={this.props.logIn} onSubmit={this.onLocalLoginSubmit} companies={this.props.userCompanies} />
                    )}
                />
                <Route path="/newpassword" exact render={() => <NewPasswordPage onSubmit={this.resetPasswordHandleSubmit} />} />
                <Route path="/resetpassword" exact render={() => <ResetPasswordPage onSubmit={this.forgotPasswordHandleSubmit} />} />
                <Route
                    path="/resetPasswordAlmostDone"
                    exact
                    render={() => (
                        <AlmostDone
                            title="Reset"
                            subtitle="Password"
                            iconComponent={LockIcon}
                            textLines={['WeSentYouLinkToResetYourPassword', 'PleaseCheckYourInbox']}
                        />
                    )}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        loggedIn: state.user.isLoggedIn,
        registerFormMode: state.user.registerFormMode,
        initialValues: state.user,
        isExternalFormSubmitting: state.user.isExternalFormSubmitting,
        loginFormMessage: state.user.loginFormMessage,
        isConfirmInvitedUserSucceed: state.user.isConfirmInvitedUserSucceed,
        userCompanies: state.user.companies,
        microsoftApplicationId: ownProps.microsoftApplicationId,
        googleApplicationId: ownProps.googleApplicationId,
        loginPageMessage: state.user.loginPageMessage,
        activeDirectoryTenant: state.configurationsData.activeDirectoryTenant,
        localLoginEnabled: ownProps.localLoginEnabled,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logIn: data => dispatch(authorizationThunks.logIn(data)),
        onExternalLoginStarted: () => dispatch(setExternalFormSubmitingFlag(true)),
        onExternalResponseFail: () => dispatch(setExternalFormSubmitingFlag(false)),
        checkUserCredentials: (data, on401) => dispatch(authorizationThunks.checkUserCredentials(data, on401)),
        resetPasswordHandleSubmit: (data, translate) => dispatch(authorizationThunks.resetPassword(data, translate)),
        forgotPasswordHandleSubmit: data => dispatch(authorizationThunks.forgotPassword(data)),
        confirmInvitedUser: (data, translate, isExternal) => dispatch(userThunks.confirmInvitedUser(data, translate, isExternal)),
        resetCredentials: () => dispatch(resetCredentials()),
        resetMessages: () => dispatch(resetMessages()),
    };
}

let loginContainer = withTranslate(LoginContainer);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(loginContainer));
