import React, { useState } from 'react';
import { withApplyActiveLanguage } from '../../containers/utils/HigherOrderComponents';
import { LANGUAGES } from '../../utils/constants';
import clsx from 'clsx';
import { Grid, makeStyles } from '@material-ui/core';

const LanguageSwitch = ({ applyActiveLanguage, savedLanguage }) => {
    const classes = useStyles();

    const [activeLanguage, setActiveLanguage] = useState(savedLanguage);

    const changeLanguage = label => {
        setActiveLanguage(label);
        applyActiveLanguage(label);
    };

    return (
        <Grid container justify="space-evenly" className={classes.container}>
            {LANGUAGES.map(language => (
                <LanguageOption language={language} key={language.code} changeLanguage={changeLanguage} activeLanguage={activeLanguage} />
            ))}
        </Grid>
    );
};

export default withApplyActiveLanguage(LanguageSwitch);

const LanguageOption = ({ language, changeLanguage, activeLanguage }) => {
    const classes = useStyles();

    const { code, flag } = language;
    return (
        <Grid item onClick={() => changeLanguage(code)}>
            <Grid
                container
                alignItems="center"
                className={clsx(classes.languageOption, activeLanguage === code && classes.activeLanguageOption)}
                spacing={1}
            >
                <Grid item>{code}</Grid>
                <Grid item>
                    <img src={flag} alt={language.name} />
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 16,
    },
    languageOption: {
        fontSize: '0.875rem',
        opacity: 0.38,
        fontWeight: 500,
        cursor: 'pointer',
    },
    activeLanguageOption: {
        opacity: 1,
        color: theme.palette.primary.main,
    },
}));
