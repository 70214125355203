import React, { Component } from 'react';
import queryString from 'querystring';
import { withRouter } from 'react-router-dom';
import { withTranslate } from '../../../containers/utils/HigherOrderComponents';
import LanguageSwitch from '../../utils/LanguageSwitch';
import ContainerHeader from '../../layout/ContainerHeader';
import BusinessIcon from '@material-ui/icons/Business';
import { Link } from '@material-ui/core';

class ConfirmEmail extends Component {
    componentDidMount() {
        let qs = this.props.location.search.substring(1);
        const values = queryString.parse(qs);
        if (values.userId) {
            this.props.confirmEmail(
                {
                    userId: values.userId,
                    code: values.code,
                    companyTaxCode: values.companyTaxCode,
                },
                this.props.translate
            );
        } else {
            this.withoutActivation = true;
        }
    }

    render() {
        return this.props.isConfirmEmailSucceed || this.withoutActivation ? (
            <div className="confirm-email-page">
                <ContainerHeader
                    iconComponent={BusinessIcon}
                    title={this.props.translate('Register')}
                    subtitle={this.props.translate('Company')}
                />
                <div className="content">
                    <div className="subtitle line line1">{this.props.translate('ThankYou')}</div>
                    {this.props.inactiveCompany ? (
                        <React.Fragment>
                            <div className="line line2">{this.props.translate('EmailConfirmedWaitForActivation')}</div>
                            <div className="line line3">{this.props.translate('WeAreNowPreparingOurSystemForYourCompany')}</div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="line line2">{this.props.translate('YouHaveSuccessfullyRegisterInSolteqPos')}</div>
                            <div className="line line3">{this.props.translate('WeAreNowPreparingOurSystemForYourCompany')}</div>
                            <div className="line line4">{this.props.translate('PleaseWaitForAnActivation')}</div>
                        </React.Fragment>
                    )}
                    <div className="line line5">{this.props.translate('YouWillBeNotifiedByEmailUsualyWithin24hours')}</div>
                    <div className="line line6">-{this.props.translate('SolteqTeam')}</div>
                    <div className="line line7">
                        <Link underline="none" href="#/login">
                            {this.props.translate('Close')}
                        </Link>
                    </div>
                </div>
                <div className="footer">
                    <LanguageSwitch />
                </div>
            </div>
        ) : (
            <span />
        );
    }
}

let confirmEmail = withTranslate(ConfirmEmail);

export default withRouter(confirmEmail);
