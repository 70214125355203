/* global Msal, myMSALObj */
import React from 'react';

const MSLogin = ({
    clientId,
    graphScopes,
    className,
    btnContent,
    onOfficeLoginResponseSuccess,
    onExternalLoginStarted,
    onExternalResponseFail,
    activeDirectoryTenant,
    ...rest
}) => {
    let signIn = () => {
        let authority;
        if (activeDirectoryTenant) {
            authority = `https://login.microsoftonline.com/${activeDirectoryTenant}`;
        } else {
            authority = 'https://login.microsoftonline.com/common';
        }

        myMSALObj = new Msal.UserAgentApplication(clientId, authority); //eslint-disable-line no-native-reassign
        onExternalLoginStarted();
        myMSALObj.loginPopup(graphScopes || ['user.read']).then(
            token => {
                let user = myMSALObj.getUser();
                onOfficeLoginResponseSuccess(user.displayableId, token);
            },
            error => {
                onExternalResponseFail();
            }
        );
    };

    return (
        <div className={className} onClick={signIn} {...rest}>
            {btnContent}
        </div>
    );
};

export default MSLogin;
