import clsx from 'clsx';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import LanguageSwitch from '../utils/LanguageSwitch';
import ContainerHeader from '../../components/layout/ContainerHeader';
import { Link } from '@material-ui/core';

const AlmostDone = ({ translate, textLines, title, subtitle, pageClass, iconComponent }) => (
    <div className={clsx('almost-done-page', pageClass)}>
        <ContainerHeader iconComponent={iconComponent} title={translate(title)} subtitle={translate(subtitle)} />

        <div className="content">
            <div className="subtitle line line1">{translate('AlmostDone')}</div>
            {textLines.map((line, index) => (
                <div className={'line line' + (index + 2)}>{translate(line)}</div>
            ))}
            <div className="line line4">
                <Link underline="none" href="#/login">
                    {translate('Close')}
                </Link>
            </div>
        </div>
        <div className="footer">
            <LanguageSwitch />
        </div>
    </div>
);

export default withRouter(withTranslate(AlmostDone));
