import React, { Component } from 'react';
import queryString from 'querystring';
import { withRouter } from 'react-router-dom';
import LanguageSwitch from '../utils/LanguageSwitch';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import ContainerHeader from '../../components/layout/ContainerHeader';
import BusinessIcon from '@material-ui/icons/Business';

class ActivateBySolteq extends Component {
    componentDidMount() {
        let qs = this.props.location.search.substring(1);
        const values = queryString.parse(qs);
        this.props.activateBySolteq(
            {
                userId: values.userId,
                code: values.code,
                companyTaxCode: values.companyTaxCode,
            },
            this.props.translate
        );
    }

    render() {
        return (
            <div className="activate-by-solteq-page">
                <ContainerHeader
                    iconComponent={BusinessIcon}
                    title={this.props.translate('Activate')}
                    subtitle={this.props.translate('Company')}
                />
                <div className="content">
                    <div className="line subtitle-small">
                        {this.props.isActivateBySolteqSucceed && this.props.translate('CompanyHasBeenActivated')}
                        {this.props.activateBySolteqFailedMessage && this.props.activateBySolteqFailedMessage}
                    </div>
                </div>
                <div className="footer">
                    <LanguageSwitch />
                </div>
            </div>
        );
    }
}

let activateBySolteq = withTranslate(ActivateBySolteq);

export default withRouter(activateBySolteq);
