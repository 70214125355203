import React from 'react';
import { setActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import store from '../../redux/store';
import _ from 'lodash';
import { LANGUAGES } from '../../utils/constants';

export const withTranslate = WrapedComponent => {
    class WithTranslate extends React.Component {
        render() {
            let translate = getTranslate(this.props.locale);
            return <WrapedComponent translate={translate} {...this.props} />;
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            locale: state.locale,
            ...ownProps,
        };
    }

    return connect(mapStateToProps)(WithTranslate);
};

export const withApplyActiveLanguage = WrapedComponent => {
    let applyActiveLanguage = language => {
        store.dispatch(setActiveLanguage(language));
    };

    class WithApplyActiveLanguage extends React.Component {
        render() {
            return <WrapedComponent applyActiveLanguage={applyActiveLanguage} savedLanguage={this.props.activeLanguage} {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return {
            activeLanguage: _.get(_.find(_.get(state, 'locale.languages', []), 'active'), 'code', LANGUAGES[0].code),
        };
    }

    return connect(mapStateToProps)(WithApplyActiveLanguage);
};
