import * as actions from '../actions/configurationsActions';
import { GET_TRANSLATIONS, GET_CONFIGURATION, DEVICE_CODE } from '../../api/routes';
import { fetchWrapper } from '../../utils/fetchWrapper';
import { addTranslationForLanguage } from 'react-localize-redux';
import { LANGUAGES_CODE, TRANSLATIONS } from '../../utils/constants';
import { init } from '../../utils/loggingAppInsights';

function loadFromLocalStorage(dispatch) {
    let allTranslations = JSON.parse(localStorage.getItem(TRANSLATIONS)) || null;
    if (!allTranslations) {
        return;
    }
    dispatch(addTranslationForLanguage(allTranslations.en, LANGUAGES_CODE.EN));
    dispatch(addTranslationForLanguage(allTranslations.fi, LANGUAGES_CODE.FI));
    dispatch(addTranslationForLanguage(allTranslations.sv, LANGUAGES_CODE.SV));
    dispatch(addTranslationForLanguage(allTranslations.pl, LANGUAGES_CODE.PL));
    dispatch(addTranslationForLanguage(allTranslations.da, LANGUAGES_CODE.DA));
}

export function getTranslations() {
    return async dispatch => {
        loadFromLocalStorage(dispatch);
        fetchWrapper(GET_TRANSLATIONS, dispatch, { method: 'GET' })
            .withSuccessHandler(allTranslations => {
                allTranslations && localStorage.setItem(TRANSLATIONS, JSON.stringify(allTranslations));
                dispatch(addTranslationForLanguage(allTranslations.en, LANGUAGES_CODE.EN));
                dispatch(addTranslationForLanguage(allTranslations.fi, LANGUAGES_CODE.FI));
                dispatch(addTranslationForLanguage(allTranslations.sv, LANGUAGES_CODE.SV));
                dispatch(addTranslationForLanguage(allTranslations.pl, LANGUAGES_CODE.PL));
                dispatch(addTranslationForLanguage(allTranslations.da, LANGUAGES_CODE.DA));
            })
            .start();
    };
}

export function getConfiguration() {
    return dispatch => {
        return fetchWrapper(GET_CONFIGURATION, dispatch)
            .withSuccessHandler(data => {
                init(data.applicationInsightsKey);
                dispatch(actions.getConfigurationSucceed(data));
            })
            .start();
    };
}

export function submitAuthorizationCode(code) {
    return dispatch => {
        return fetchWrapper(`${DEVICE_CODE}?userCode=${code}`, dispatch).start();
    };
}
