import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component, loggedIn, componentRender, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            loggedIn ? (
                getComponent(componentRender, component, Object.assign({}, props, { ...rest }))
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const getComponent = (componentRender, Component, props) => {
    return !!componentRender ? componentRender(Component, props) : <Component {...props} />;
};

export default PrivateRoute;
