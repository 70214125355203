import React from 'react';
import { Field, reduxForm } from 'redux-form';
import LanguageSwitch from '../../utils/LanguageSwitch';
import { withTranslate } from '../../../containers/utils/HigherOrderComponents';
import { required, emailValidation } from '../../../utils/validators';
import TextFieldRenderer from '../../renderers/TextFieldRenderer';
import ContainerHeader from '../../layout/ContainerHeader';
import BusinessIcon from '@material-ui/icons/Business';
import { Link, Button } from '@material-ui/core';

const ResendActivationEmailPage = ({ translate, handleSubmit }) => (
    <div className="resend-activation-email-page">
        <form onSubmit={handleSubmit}>
            <ContainerHeader iconComponent={BusinessIcon} title={translate('Register')} subtitle={translate('Company')} />

            <div className="content">
                <div className="subtitle line line1">{translate('ResendVerificationLink')}</div>
                <div className="line line2 subtitle-small">{translate('PleaseEnterEmailAndWeWillResendVerificationLink')}</div>
                <div className="responsive-form-container">
                    <div className="form-row">
                        <Field
                            component={TextFieldRenderer}
                            validate={[required, emailValidation]}
                            label={translate('Email')}
                            name="email"
                            id="email"
                            type="text"
                        />
                    </div>
                    <div className="form-row">
                        <Button variant="contained" color="primary" fullWidth type="submit">
                            {translate('Resend')}
                        </Button>
                    </div>
                </div>
                <div className="line line4 subtitle-small">
                    <Link underline="none" href="#/login">
                        {translate('GoBack')}
                    </Link>
                </div>
            </div>
            <div className="footer">
                <LanguageSwitch />
            </div>
        </form>
    </div>
);

let resendActivationEmailPage = withTranslate(ResendActivationEmailPage);

export default reduxForm({ form: 'resendActivationEmailForm' })(resendActivationEmailPage);
