const Default = {
    PRIMARY: '#c05ede',
    TEXT_DARK_COLOR: '#212b36',
    INPUT_BORDER_COLOR: 'rgba(0,0,0, 0.5)',
    WHITE: '#fff',
    ERROR: '#dd4d7d',
    HEADER: {
        GRADIENT: {
            LEFT: '#f2578f',
            RIGHT: '#c05ede',
        },
    },
    SECONDARY: '#dd3978',
};

const Tulli = {
    PRIMARY: '#00205b',
    TEXT_DARK_COLOR: '#212b36',
    INPUT_BORDER_COLOR: '#00205b',
    WHITE: '#fff',
    ERROR: '#dd4d7d',
    HEADER: {
        LOGO: '/images/tulli_logo.svg',
    },
    SECONDARY: '#f2a900',
    BACKGROUND_IMAGE: '/images/background.png',
};

export const Layouts = {
    Default,
    Tulli,
};
