import React from 'react';
import clsx from 'clsx';
import { makeStyles, Grid } from '@material-ui/core';
import styled from 'styled-components';

const ContainerHeader = ({ iconComponent: IconComponent, title, subtitle = undefined }) => {
    const classes = useStyles();
    const classes2 = useStyles2();
    return (
        <div className={clsx('header', classes.header)}>
            <Grid container direction="row" justify="center" alignItems="center" style={{ margin: 'auto' }}>
                <Grid item>
                    <IconComponent className={classes2.icon} />
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Title>&nbsp;{title}</Title>
                        </Grid>
                        {subtitle && (
                            <Grid item>
                                <Subtitle>{subtitle}</Subtitle>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ContainerHeader;

const useStyles2 = makeStyles(theme => ({
    icon: {
        fontSize: '2.1rem',
        color: theme.palette.common.white,
        paddingTop: 6,
    },
}));

const useStyles = makeStyles(theme => {
    const { gradient } = theme;
    if (gradient) {
        return {
            header: {
                backgroundImage: `linear-gradient(108deg, ${gradient.left}, ${gradient.right})`,
            },
        };
    } else {
        return {
            header: {
                backgroundColor: theme.palette.primary.main,
            },
        };
    }
});

const Title = styled.h4`
    display: block;
    text-align: left;
    color: #fff;
    letter-spacing: 0.3px;
    font-weight: normal;
    width: 100%;
    margin: 0;
`;

const Subtitle = styled.p`
    margin: 0;
    margin-top: -6px;
    text-align: right;
    box-sizing: border-box;
    font-weight: bold;
    color: #fff;
    font-size: 0.875rem;
    text-transform: lowercase;
`;
