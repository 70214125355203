import * as authorizationActions from '../redux/actions/authorizationActions';
import { notificationMessage } from '../redux/actions/notificationActions';
import { NOTIFICATION_MESSAGE_TYPES } from './constants';
import { getAppInsights } from './loggingAppInsights';

class FetchWrapper {
    constructor(route, dispatch, config, fetch) {
        this.route = route;
        this.config = config;
        this.dispatch = dispatch;
        this.fetch = fetch.bind(window);
        this.errorHandlers = {
            503: () => this.dispatch(notificationMessage(NOTIFICATION_MESSAGE_TYPES.FAIL, undefined, 'ServiceUnavailable')),
        };
        this.successHandler = () => {};
    }

    withStartedHandler(startedHandler) {
        this.startedHandler = startedHandler;
        return this;
    }

    withSuccessHandler(successHandler) {
        this.successHandler = successHandler;
        return this;
    }

    withDefaultErrorHandler(errorHandler) {
        this.errorHandler = errorHandler;
        return this;
    }

    withCustomErrorHandler(statusCode, errorHandler) {
        this.errorHandlers = this.errorHandlers || {};
        this.errorHandlers[statusCode] = errorHandler;
        return this;
    }

    withUnauthorizedRedirection() {
        this.errorHandlers = this.errorHandlers || {};
        this.errorHandlers['401'] = function() {
            this.dispatch(authorizationActions.logOut());
        };

        return this;
    }

    serverErrorMessageHandler = async response => {
        let data = undefined;
        try {
            data = await response.json();
        } catch (error) {
            return false;
        }

        data = data.message || data.Message || data.error || data;
        let message;
        try {
            const obj = JSON.parse(data);
            message = obj.message || obj.error || obj;
        } catch (error) {
            message = data;
        }

        message && this.dispatch(notificationMessage(NOTIFICATION_MESSAGE_TYPES.FAIL, undefined, message));

        return !!message;
    };

    async start() {
        if (this.startedHandler) {
            this.startedHandler();
        }
        let response;
        try {
            response = await this.fetch(this.route, this.config);
        } catch (e) {
            const appinsights = getAppInsights();
            appinsights?.trackException(e, { route: this.route, code: 503 });
            this.errorHandlers['503']();
            return false;
        }
        if ((response.status >= 200 && response.status < 300) || response.status === 304) {
            try {
                let jsonResult = await response.json();
                this.successHandler(jsonResult);
                return jsonResult;
            } catch (e) {
                this.successHandler();
                return true;
            }
        } else {
            if (this.errorHandlers[response.status]) {
                this.errorHandlers[response.status](response);
            } else if (this.errorHandler) {
                this.errorHandler(response);
            } else {
                this.serverErrorMessageHandler(response);
            }

            return false;
        }
    }
}

class ConventionFetch {
    constructor(createFetchWrapper, dispatch) {
        this.createFetchWrapper = createFetchWrapper;
        this.dispatch = dispatch;
    }

    withActionType(actionType) {
        this.actionType = actionType;
        return this;
    }

    prepare({ proccessSuccessJson, startedActionExtraParams = {}, succeedActionExtraParams = {}, failedActionExtraParams = {} } = {}) {
        let startedActionName = `${this.actionType}_STARTED`;
        let succeedActionName = `${this.actionType}_SUCCEED`;
        let failedActionName = `${this.actionType}_FAILED`;
        let fetchWrapper = this.createFetchWrapper();
        fetchWrapper
            .withStartedHandler(() =>
                this.dispatch({
                    type: startedActionName,
                    ...startedActionExtraParams,
                })
            )
            .withSuccessHandler(responseJson => {
                let processedJson = proccessSuccessJson ? proccessSuccessJson(responseJson) : responseJson;
                this.dispatch({
                    type: succeedActionName,
                    data: processedJson,
                    ...succeedActionExtraParams,
                });
            })
            .withDefaultErrorHandler(async response => {
                if (!(await fetchWrapper.serverErrorMessageHandler(response))) {
                    this.dispatch({
                        type: failedActionName,
                        ...failedActionExtraParams,
                    });
                }
            });

        return fetchWrapper;
    }
}

export function fetchWrapper(route, dispatch, config) {
    return new FetchWrapper(route, dispatch, config, fetch);
}

export function conventionFetch(route, dispatch, config) {
    return new ConventionFetch(() => fetchWrapper(route, dispatch, config, fetch), dispatch);
}

export function authFetchWrapper(route, dispatch, config) {
    return new FetchWrapper(route, dispatch, config, authFetch);
}

export function conventionAuthFetch(route, dispatch, config) {
    return new ConventionFetch(() => authFetchWrapper(route, dispatch, config), dispatch);
}

export function addMassageKeyToAction(prefix) {
    return {
        succeedActionExtraParams: { messageKey: prefix + 'Succeeded' },
        failedActionExtraParams: { messageKey: prefix + 'Failed' },
    };
}
