import * as actionTypes from '../actions/types/configurationsActionTypes';

export default function configurationsReducer(
    state = {
        microsoftApplicationId: null,
        googleApplicationId: null,
        fallbackDefaultApplicationUrl: null,
        activeDirectoryTenant: null,
        localLoginEnabled: true,
    },
    action
) {
    if (action.type === actionTypes.GET_CONFIGURATION_SUCCEED) {
        const {
            microsoftApplicationId,
            googleApplicationId,
            fallbackDefaultApplicationUrl,
            activeDirectoryTenant,
            localLoginEnabled,
            identityServerLayoutSchema,
        } = action.data;
        return {
            ...state,
            microsoftApplicationId,
            googleApplicationId,
            fallbackDefaultApplicationUrl,
            activeDirectoryTenant,
            localLoginEnabled: localLoginEnabled === undefined ? true : localLoginEnabled,
            identityServerLayoutSchema: identityServerLayoutSchema,
            configurationLoaded: true,
        };
    }

    return Object.assign({}, state);
}
