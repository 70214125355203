import React from 'react';
import { LOCAL_REGISTATION_TYPES } from '../../utils/constants';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import LanguageSwitch from '../utils/LanguageSwitch';
import { withRouter } from 'react-router-dom';
import { HeaderLogo } from '../layout/Logo';
import ContainerHeader from '../../components/layout/ContainerHeader';
import BusinessIcon from '@material-ui/icons/Business';
import { Link } from '@material-ui/core';
import { ExternalLoginButtons } from './ExternalLoginButtons';
import { LoginOutlinedButton } from './LoginOutlinedButton';

const RegisterChoice = ({
    translate,
    googleApplicationId,
    onGoogleResponseSuccess,
    onExternalResponseFail,
    onExternalLoginStarted,
    microsoftApplicationId,
    onOfficeLoginResponseSuccess,
    isExternalFormSubmitting,
    activeDirectoryTenant,
    localLoginEnabled,
    history,
}) => {
    const setRegistrationModeAndRedirect = mode => {
        let path = '/register';
        path += mode ? '?mode=' + mode : '';
        history.push(path);
    };

    return (
        <div className="register-choice">
            <ContainerHeader iconComponent={BusinessIcon} title={translate('Register')} subtitle={translate('Company')} />
            <div className="content">
                <div className="subtitle line line1">{translate('WelcomeTo')}</div>
                <div className="line line2">
                    <HeaderLogo />
                </div>
                <div className="line line3 subtitle-small">{translate('PleaseSignUpToProceed')}</div>
                <div className="login-btns-container">
                    <ExternalLoginButtons
                        googleApplicationId={googleApplicationId}
                        onGoogleResponseSuccess={onGoogleResponseSuccess}
                        onExternalResponseFail={onExternalResponseFail}
                        onExternalLoginStarted={onExternalLoginStarted}
                        microsoftApplicationId={microsoftApplicationId}
                        onOfficeLoginResponseSuccess={onOfficeLoginResponseSuccess}
                        isExternalFormSubmitting={isExternalFormSubmitting}
                        activeDirectoryTenant={activeDirectoryTenant}
                    />
                    {localLoginEnabled && (
                        <React.Fragment>
                            <LoginOutlinedButton
                                label={translate('SignUpWithExistingAccount')}
                                onClick={() => setRegistrationModeAndRedirect(LOCAL_REGISTATION_TYPES.EXISTING_ACCOUNT)}
                            />
                            <LoginOutlinedButton
                                label={translate('SignUpWithNewAccount')}
                                onClick={() => setRegistrationModeAndRedirect(LOCAL_REGISTATION_TYPES.NEW_ACCOUNT)}
                            />
                        </React.Fragment>
                    )}
                </div>
                {localLoginEnabled && (
                    <div className="line line4 subtitle-small">
                        <Link underline="none" href="#/resendActivationEmail">
                            {translate('ResendVerificationLink')}
                        </Link>
                    </div>
                )}
                <div className="line line5 subtitle-small">
                    {translate('AlreadyRegistered')}? &nbsp;
                    <Link underline="none" href="#/login">
                        {translate('SignIn')}
                    </Link>
                </div>
            </div>
            <div className="footer">
                <LanguageSwitch />
            </div>
        </div>
    );
};

let registerChoice = withTranslate(RegisterChoice);
export default withRouter(registerChoice);
