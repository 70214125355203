import React from 'react';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import LanguageSwitch from '../../utils/LanguageSwitch';
import { withTranslate } from '../../../containers/utils/HigherOrderComponents';
import { required, emailValidation, passwordValidation } from '../../../utils/validators';
import TextFieldRenderer from '../../renderers/TextFieldRenderer';
import { HeaderLogo } from '../../layout/Logo';
import ContainerHeader from '../../layout/ContainerHeader';
import LockIcon from '@material-ui/icons/Lock';
import { Button } from '@material-ui/core';

const NewPasswordPage = ({ translate, handleSubmit }) => (
    <form onSubmit={handleSubmit} className="new-password-page">
        <ContainerHeader iconComponent={LockIcon} title={translate('New')} subtitle={translate('Password')} />
        <div className="content">
            <div className="subtitle line line1">{translate('WelcomeTo')}</div>
            <div className="line line2">
                <HeaderLogo />
            </div>
            <div className="line line3 subtitle-small">{translate('PleaseEnterNewPassword')}</div>
            <div className="responsive-form-container">
                <div className="form-row">
                    <Field label={translate('Email')} component={TextFieldRenderer} name="email" validate={[required, emailValidation]} />
                </div>
                <div className="form-row">
                    <Field
                        label={translate('Password')}
                        component={TextFieldRenderer}
                        name="password"
                        type="password"
                        validate={[required, passwordValidation]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        label={translate('ConfirmPassword')}
                        component={TextFieldRenderer}
                        name="confirmPassword"
                        type="password"
                        validate={[required, passwordValidation]}
                    />
                </div>
                <div className="form-row">
                    <Button variant="contained" color="primary" type="submit">
                        {translate('Submit')}
                    </Button>
                </div>
            </div>
        </div>
        <div className="footer">
            <LanguageSwitch />
        </div>
    </form>
);

let newPasswordPage = withTranslate(NewPasswordPage);

export default withRouter(reduxForm({ form: 'newPassword' })(newPasswordPage));
