import React from 'react';
import { connect } from 'react-redux';
import { LANGUAGES } from '../../utils/constants';
import { initialize, setActiveLanguage } from 'react-localize-redux';
import { getTranslations } from '../../redux/thunks/configurationsThunks';

function withTranslations(WrappedComponent) {
    class LanguageContainer extends React.Component {
        componentWillMount() {
            // eslint-disable-next-line no-template-curly-in-string
            let missingTranslationMsg = '${key}';
            this.props.initialize(LANGUAGES, { missingTranslationMsg });

            this.props.fetchTranslations();
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            initialize: (languages, defaultLanguage, options) => dispatch(initialize(languages, defaultLanguage, options)),
            fetchTranslations: () => dispatch(getTranslations()),
            setLanguage: language => dispatch(setActiveLanguage(language)),
        };
    }

    return connect(null, mapDispatchToProps)(LanguageContainer);
}

export default withTranslations;
