import React, { useEffect } from 'react';
import * as errorDescriptionThunks from '../../redux/thunks/errorDescriptionThunks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getQueryStringValues } from '../../utils/helpers';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

import Page from '../../components/layout/Page';

const mapStateToProps = state => {
    return { ...state.errorDescription };
};

const mapDispatchToProps = dispatch => ({
    fetchErrorDescription: errorId => dispatch(errorDescriptionThunks.fetchErrorDescription(errorId)),
});

export const ErrorPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(props => {
        const { location, error, fetchErrorDescription } = props;
        useEffect(() => {
            const doFetchErrorDescription = async () => {
                const query = getQueryStringValues();
                return fetchErrorDescription(query.errorId);
            };

            doFetchErrorDescription();
        }, [location, fetchErrorDescription]);

        return (
            <Page>
                <Grid container={true} direction="column" spacing={16} style={{ padding: '2rem' }}>
                    <Grid item={true}>
                        <Typography variant="h5">Something went wrong</Typography>
                    </Grid>
                    <Grid item={true}>
                        {error ? (
                            <Grid container={true} direciont="column" spacing={8}>
                                <Grid item={true}>
                                    <Typography variant="body1">{error.message}</Typography>
                                </Grid>
                                <Grid item={true}>
                                    <Typography variant="caption">Request id: {error.requestId}</Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <CircularProgress />
                        )}
                    </Grid>
                </Grid>
            </Page>
        );
    })
);
