import React from 'react';
import backgroundImage from '../../images/background-img.jpg';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const Page = ({ children, maxWidth }) => {
    const classes = useStyles({ maxWidth });
    return (
        <div className={clsx(classes.outerContainer, 'non-authorized-layout-container')}>
            <div className={clsx('layout-content', classes.innerContainer)}>{children}</div>
        </div>
    );
};

export default Page;

const useStyles = makeStyles(theme => ({
    outerContainer: {
        '& .line1': {
            color: theme.palette.primary.main,
        },
        backgroundImage: theme.background.url ? `url(${theme.background.url})` : `url(${backgroundImage})`,
        minHeight: '100vh',
    },
    innerContainer: {
        background: theme.palette.common.white,
        width: props => (props.maxWidth ? 'auto' : '360px'),
    },
}));
