import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../../components/layout/LoadingIndicator';

class MainLoadingIndicator extends Component {
    render() {
        return this.props.isVisible ? <LoadingIndicator /> : <span />;
    }
}

const isVisible = forms => {
    for (let form in forms) {
        if (forms[form].submitting) {
            return true;
        }
    }

    return false;
};

function mapStateToProps(state) {
    return {
        isVisible: isVisible(state.form),
    };
}

export default connect(mapStateToProps)(MainLoadingIndicator);
