import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import LanguageSwitch from '../utils/LanguageSwitch';
import { required, passwordValidation } from '../../utils/validators';
import { withRouter } from 'react-router-dom';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import TextFieldRenderer from '../renderers/TextFieldRenderer';
import { HeaderLogo } from '../layout/Logo';
import ContainerHeader from '../../components/layout/ContainerHeader';
import LockIcon from '@material-ui/icons/Lock';
import { Button } from '@material-ui/core';

class ConfirmInvitedUser extends Component {
    constructor(props) {
        super(props);
        this.setPassword = this.props.location.search.indexOf('setPassword') !== -1;
        !this.setPassword && this.props.noPasswordUserInvitation();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.inactiveCompany) {
            this.props.history.push('/confirmEmail');
        }

        if (nextProps.isConfirmInvitedUserSucceed && !nextProps.inactiveCompany) {
            this.props.history.push('/login');
        }
    }

    render() {
        let translate = this.props.translate;
        return (
            this.setPassword && (
                <form onSubmit={this.props.handleSubmit} className="confirm-invited-user-page">
                    <ContainerHeader
                        iconComponent={LockIcon}
                        title={this.props.translate('Confirm')}
                        subtitle={this.props.translate('invitation')}
                    />
                    <div className="content">
                        <div className="subtitle line line1">{this.props.translate('WelcomeTo')}</div>
                        <div className="line line2">
                            <HeaderLogo />
                        </div>
                        <div className="line line3 subtitle-small">{this.props.translate('PleaseEnterPassword')}</div>
                        <div className="responsive-form-container">
                            <div className="form-row">
                                <Field
                                    component={TextFieldRenderer}
                                    label={translate('Password')}
                                    name="password"
                                    id="password"
                                    type="password"
                                    validate={[required, passwordValidation]}
                                />
                            </div>
                            <div className="form-row">
                                <Field
                                    component={TextFieldRenderer}
                                    label={translate('ConfirmPassword')}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    type="password"
                                    validate={[required, passwordValidation]}
                                />
                            </div>
                            <div className="form-row">
                                <Button variant="contained" color="primary" fullWidth type="submit">
                                    {this.props.translate('Submit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <LanguageSwitch />
                    </div>
                </form>
            )
        );
    }
}

let confirmInvitedUser = withTranslate(ConfirmInvitedUser);

export default withRouter(reduxForm({ form: 'confirmInvitedUser' })(confirmInvitedUser));
