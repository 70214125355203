import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
let appInsights = null;

export const init = instrumentationKey => {
    if (instrumentationKey) {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                },
            },
        });
        appInsights.loadAppInsights();
    }
};

export const withTracking = Component => withAITracking(reactPlugin, Component);

export const getAppInsights = () => appInsights;
