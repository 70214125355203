import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import LanguageSwitch from '../utils/LanguageSwitch';
import { required } from '../../utils/validators';
import { withTranslate } from '../../containers/utils/HigherOrderComponents';
import TextFieldRenderer from '../renderers/TextFieldRenderer';
import { HeaderLogo } from '../layout/Logo';
import ContainerHeader from '../../components/layout/ContainerHeader';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link, Button } from '@material-ui/core';

class LocalLoginForm extends Component {
    componentWillUnmount() {
        this.props.resetMessages();
    }

    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="local-login-page">
                    <ContainerHeader iconComponent={AccountCircleIcon} title={this.props.translate('SignIn')} />
                    <div className="content">
                        <div className="subtitle line line1">{this.props.translate('WelcomeTo')}</div>
                        {this.props.loginFormMessage ? (
                            <div className="line message">{this.props.translate(this.props.loginFormMessage)}</div>
                        ) : (
                            <div className="line line2">
                                <HeaderLogo />
                            </div>
                        )}

                        <div className="line line3 subtitle-small">{this.props.translate('PleaseSignInToProceed')}</div>
                        <div className="local-login-container responsive-form-container">
                            <div className="form-row">
                                <Field
                                    component={TextFieldRenderer}
                                    label={this.props.translate('Email')}
                                    id="email"
                                    name="email"
                                    type="text"
                                    fullWidth
                                    validate={[required]}
                                />
                            </div>
                            <div className="form-row">
                                <Field
                                    component={TextFieldRenderer}
                                    label={this.props.translate('Password')}
                                    id="password"
                                    name="password"
                                    type="password"
                                    fullWidth
                                    validate={[required]}
                                />
                            </div>
                            <div className="form-row">
                                <Button variant="contained" color="primary" fullWidth type="submit">
                                    {this.props.translate('SignIn')}
                                </Button>
                            </div>
                        </div>
                        <div className="line line4 subtitle-small">
                            <p>
                                <Link underline="none" href="#/login">
                                    {this.props.translate('GoBack')}
                                </Link>
                            </p>
                            <p>
                                <Link underline="none" href="#/resetPassword">
                                    {this.props.translate('ForgotPassword')}
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="footer">
                        <LanguageSwitch />
                    </div>
                </div>
            </form>
        );
    }
}

let localLoginForm = withTranslate(LocalLoginForm);

export default reduxForm({ form: 'login' })(localLoginForm);
