import * as actionTypes from '../actions/types/subscriptionActionTypes';

let defaultState = {
    availableApplications: null,
};

export default function subscriptionReducer(state = defaultState, action) {
    if (action.type === actionTypes.FETCH_AVAILABLE_APPLICATIONS_SUCCEED) {
        return { ...state, availableApplications: action.availableApplications };
    } else {
        return { ...state };
    }
}
