import React, { Component } from 'react';
import { getQueryStringValues } from '../../utils/helpers';
import LoadingIndicator from '../layout/LoadingIndicator';

class LogoutPage extends Component {
    constructor(props) {
        super(props);
        let qs = getQueryStringValues();

        this.state = {
            postLogoutRedirectUri: qs.postLogoutRedirectUri,
            signOutIframeUrl: qs.signOutIframeUrl,
            clientName: qs.clientName,
        };
    }

    componentDidMount() {
        window.location = this.state.postLogoutRedirectUri;
    }

    render() {
        const Iframe = src => (
            <div>
                <iframe title="iframe" src={src} height="0" width="0" />
            </div>
        );

        return (
            <LoadingIndicator label={this.props.translate('Redirecting')}>
                {this.state.signOutIframeUrl && Iframe(this.state.signOutIframeUrl)}
            </LoadingIndicator>
        );
    }
}

export default LogoutPage;
