import { NOTIFICATION_MESSAGE } from '../actions/types/notificationActionTypes';

export default function notificationReducer(state = {}, action) {
    if (action.type === NOTIFICATION_MESSAGE) {
        state.message = {
            type: action.messageType,
            title: action.title,
            message: action.message,
            messageKeys: action.messageKeys,
            ts: new Date().getTime(),
        };
        return Object.assign({}, state);
    }

    return Object.assign({}, state);
}
