import { EMAIL_REGEX } from './constants';
import jsvat from 'jsvat';
import { getTranslate } from 'react-localize-redux';
import store from '../redux/store';

function getTranslateFunction() {
    const state = store.getState();
    return getTranslate(state.locale);
}

function isDemoTaxCode(value) {
    const demoPrefix = value.substr(0, 4);
    const taxNumber = value.substr(4, 6);
    return demoPrefix === 'DEMO' && !isNaN(taxNumber) && value.length === 10;
}

export const required = value => {
    const translate = getTranslateFunction();
    if (value !== null && value !== undefined) {
        value = value.toString();
        return value.trim() ? undefined : translate('Required');
    }

    return translate('Required');
};

export const emailValidation = value => {
    const translate = getTranslateFunction();
    const regexp = EMAIL_REGEX;
    return regexp.test(String(value).toLowerCase()) ? undefined : translate('InvalidEmailFormat');
};

export const taxCodeValidation = value => {
    const translate = getTranslateFunction();
    if (isDemoTaxCode(value)) {
        return undefined;
    }
    return jsvat.checkVAT(value).isValid ? undefined : translate('InvalidVatNumber');
};

export const passwordValidation = value => {
    const translate = getTranslateFunction();
    return String(value).length >= 9 ? undefined : translate('PasswordRequirement');
};
