import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as subscriptionThunks from '../../redux/thunks/subscriptionThunks';
import LoadingIndicator from '../../components/layout/LoadingIndicator';

class RedirectAfterLogin extends Component {
    componentDidMount() {
        if (!this.props.returnUrl) {
            this.props.fetchAvailableApplications();
        }
    }
    render() {
        this.redirect();

        return <LoadingIndicator label="Signing..." />;
    }

    redirect() {
        const redirectUrl = this.getRedirectUrl();
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    }

    getRedirectUrl() {
        if (this.props.returnUrl) {
            return this.props.returnUrl;
        }

        if (this.props.availableApplications && this.props.availableApplications.length === 1) {
            const defaultApplication = this.props.availableApplications[0];
            return defaultApplication.url;
        }

        return this.props.fallbackRedirectUrl;
    }
}

function mapStateToProps(state) {
    return {
        returnUrl: state.user.returnUrl,
        availableApplications: state.subscription.availableApplications,
        fallbackRedirectUrl: state.configurationsData.fallbackDefaultApplicationUrl,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchAvailableApplications: () => dispatch(subscriptionThunks.loadAvailableApplications()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectAfterLogin);
