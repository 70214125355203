import React, { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import LayoutContainer from './containers/layouts/LayoutContainer';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { Layouts } from './layout/layoutConfiguration';
import { connect } from 'react-redux';

export const App = connect(state => ({
    identityServerLayoutSchema: state.configurationsData.identityServerLayoutSchema,
}))(({ identityServerLayoutSchema }) => {
    const [schema, setSchema] = useState(Layouts.Default);

    useEffect(() => {
        if (identityServerLayoutSchema) {
            setSchema(Layouts[identityServerLayoutSchema]);
        } else {
            setSchema(Layouts.Default);
        }
    }, [identityServerLayoutSchema]);

    const muiTheme = buildMuiTheme(schema);
    const styledComponentsTheme = buildStyledCompontentsTheme(schema);

    return (
        <ThemeProvider theme={styledComponentsTheme}>
            <MuiThemeProvider theme={muiTheme}>
                <Router>
                    <LayoutContainer />
                </Router>
            </MuiThemeProvider>
        </ThemeProvider>
    );
});

const buildMuiTheme = Colors => ({
    ...createMuiTheme({
        palette: {
            primary: {
                main: Colors.PRIMARY,
            },
            secondary: {
                main: Colors.SECONDARY,
            },
            error: {
                main: Colors.ERROR,
            },
        },
        typography: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
        },
        input: {
            height: INPUT.HEIGHT,
        },
        overrides: {
            MuiButton: {
                root: {
                    color: '#fff',
                    fontSize: '0.875rem',
                    textTransform: 'unset',
                    boxShadow: 'none',
                },
                outlined: {
                    padding: '8px 16px',
                },
                contained: {
                    padding: '8px 16px',
                    color: '#fff',

                    '&:hover': {
                        opacity: '0.8',
                    },
                },
                text: {
                    fontWeight: 'bold',
                    padding: '8px 16px',
                    color: Colors.PRIMARY,
                },
                MuiInputBase: {
                    root: {
                        minHeight: INPUT.HEIGHT,
                    },
                },
                MuiOutlinedInput: {
                    root: {
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: Colors.INPUT_BORDER_COLOR,
                            },
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: `${Colors.PRIMARY} !important`,
                        },
                    },
                    notchedOutline: {
                        borderColor: `${Colors.INPUT_BORDER_COLOR}`,
                    },
                    input: {
                        paddingTop: '13.5px',
                        paddingBottom: '13.5px',
                    },
                },
                MuiInputLabel: {
                    root: {
                        color: Colors.TEXT_DARK_COLOR,
                    },
                    outlined: {
                        transform: 'translate(14px, 16px) scale(1)',
                    },
                    shrink: {},
                },
            },
        },
    }),
    gradient: Colors.HEADER.GRADIENT
        ? {
              left: Colors.HEADER.GRADIENT.LEFT,
              right: Colors.HEADER.GRADIENT.RIGHT,
          }
        : undefined,
    background: {
        url: Colors.BACKGROUND_IMAGE,
    },
    header: {
        logo: Colors.HEADER.LOGO,
    },
});

const buildStyledCompontentsTheme = Colors => ({
    primary: {
        main: Colors.PRIMARY,
    },
});

const INPUT = {
    HEIGHT: 46,
};
