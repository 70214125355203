import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

export const LoginOutlinedButton = ({ onClick, label }) => {
    const classes = useStyles();
    return (
        <Button variant="outlined" color="primary" fullWidth onClick={onClick} classes={{ ...classes }}>
            {label}
        </Button>
    );
};

const useStyles = makeStyles({
    label: {
        lineHeight: 2.5,
    },
    root: {
        marginBottom: 22,
        padding: 0,
    },
});
