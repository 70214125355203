import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { errorNotificationSettings } from '../../utils/notificationSettings';
import { NOTIFICATION_MESSAGE_TYPES } from '../../utils/constants';

class NotificationContainer extends Component {
    getToasterOptions = () => {
        let defaultOptions = {
            timeOut: 3000,
            newestOnTop: false,
            preventDuplicates: true,
            position: 'top-right',
            transitionIn: 'fadeIn',
            transitionOut: 'fadeOut',
        };
        return !this.props.isLoggedIn
            ? {
                  ...defaultOptions,
                  position: 'top-center',
                  transitionIn: 'bounceInDown',
                  transitionOut: 'bounceOutUp',
                  className: 'solteq-snackbar',
                  timeOut: 6000,
              }
            : defaultOptions;
    };

    componentDidUpdate(prevProps) {
        if (!this.props.message || (prevProps.message && this.props.message.ts === prevProps.message.ts)) {
            return;
        }

        let { type, title, message, messageKeys } = this.props.message;
        const settings = this.props.isLoggedIn ? null : errorNotificationSettings;
        const translate = this.props.translate;

        if (!message && messageKeys) {
            message = messageKeys instanceof Array ? messageKeys.map(key => translate(key)).join(', ') : translate(messageKeys);
        }

        const translatedTitle = translate(title || type);
        switch (type) {
            case NOTIFICATION_MESSAGE_TYPES.FAIL:
                toastr.error(translatedTitle, message, settings);
                break;
            case NOTIFICATION_MESSAGE_TYPES.INFO:
                toastr.info(translatedTitle, message, settings);
                break;
            case NOTIFICATION_MESSAGE_TYPES.SUCCESS:
                toastr.success(translatedTitle, message, settings);
                break;
        }
    }

    render() {
        return <ReduxToastr {...this.getToasterOptions()} />;
    }
}
function mapStateToProps(state, ownProps) {
    return {
        message: state.notificationData.message,
        translate: ownProps.translate || (x => x),
        isLoggedIn: state.user.isLoggedIn,
    };
}

export default connect(mapStateToProps)(NotificationContainer);
